/* @charset "utf-8" */
/* CSS Document */

// IMPORT - START

@import form-initialization-general

// IMPORT - END


// GENERAL - START

/* FORM - START */

form
	margin-bottom: $SPACE_HUGE
form.FormInsertConversation
	div.DivConversationGeneral
		div.DivConversationContent
			textarea
				border-bottom-left-radius: 0px
				border-bottom-right-radius: 0px
				resize: none
				margin-bottom: 0px
				padding-bottom: 0px

div.DivForm, form div.DivForm fieldset, div.DivContainerSurvey
	input, textarea
		+setBoxSizing(border-box)
	input
		height: $HEIGHT_INPUT_SINGLELINE
	input, select, textarea, textarea.TextareaDisable
		margin-bottom: $SPACE_NARROW
		margin-top: $SPACE_NARROW
		width: 100%
		min-width: $WIDTH_INPUT_SQUARE
		padding-top: $PADDING_INPUT_EDGE
		padding-bottom: $PADDING_INPUT_EDGE
		padding-right: $SPACE_MEDIUM
		padding-left: $SPACE_MEDIUM
		font-size: $FONT_LABEL_SUBTITLE
		border: 1px solid #999090
		+setBorderRadius($RADIUS_INPUT_GENERAL)
	input.InputToken, input.InputTokenWhatsapp
		min-width: $WIDTH_INPUT_SQUARE
		width: $WIDTH_INPUT_SQUARE
		text-align: center
	input::-webkit-outer-spin-button, input::-webkit-inner-spin-button
		+setAppearance(none)
		margin: 0px
	input[type=number]
		+setAppearance(textfield)
	mat-slider
		width: 100%
		height: 200px
	select
		min-height: $HEIGHT_SELECT_SINGLELINE
		background-repeat: no-repeat
		background-position: right
		+setAppearance(none)
	textarea
		height: $HEIGHT_TEXTAREA_GENERAL
	textarea.TextareaDescriptionHeader
		height: $HEIGHT_TEXTAREA_HEADER !important
		font-size: $FONT_INPUT_TEXTAREA_HEADERHEADLINE
		margin: 0px
	textarea.TextareaDisable
		height: $HEIGHT_TEXTAREA_READONLY
		resize: none
		padding: 0
		+setBorderRadius(0px)
	div.DivRadioButtonContainerVertical
		flex-direction: column
	div.DivRadioButtonContainer, div.DivRadioButtonContainerVertical, div.DivCheckBoxContainer
		label.LabelRadioButtonContainer, label.LabelCheckboxContainer
			line-height: 1.1
			display: flex !important
			align-items: center
			flex-direction: row
			gap: 10px
			input[type=radio], input[type=checkbox]
				top: 0px
				left: 0px
				border: 3px solid #918f8f
				-webkit-appearance: none
				appearance: none
				background-color: transparent
				margin: 0 0 5px 0
				font: inherit
				color: black
				transform: translateY(-0.075em)
				display: grid
				place-content: center
				padding: 5px 10px !important
				&::before
					content: ""
					transform: scale(0)
					transition: 100ms transform ease-in-out
					background-color: transparent
				&:checked
					&::before
						transform: scale(1)
			&:last-of-type
				margin: 0
		label.LabelRadioButtonContainer
			margin-left: 10px !important
			input[type=radio]
				width: 38px !important
				height: 38px !important
				border-radius: 50%
				margin: 0 !important
				&::before
					width: 22px
					height: 22px
					border-radius: 50%
					box-shadow: inset 23px 23px #064F89
		label.LabelRadioButtonActive
			input[type=radio]
				border: 3px solid #064F89
		label.LabelCheckboxContainer
			text-align: justify
			input[type=checkbox]
				width: 30px !important
				height: 30px !important
				min-height: 0px
				min-width: 0px
				border-radius: 8px
				&::before
					content: ""
					width: 15px
					height: 15px
					border-radius: 4px
					box-shadow: inset 15px 15px #064F89
	div.DivRadioButtonContainer, div.DivRadioButtonContainerVertical
		display: flex
		div.DivRadioButton
			margin-right: $SPACE_LITTLE
			width: $SIZE_INPUT_RADIO
			height: $SIZE_INPUT_RADIO
			padding: $SPACE_TINY
			border-style: solid
			border-width: $WIDTH_BORDER_INPUTTHICK
			+setBorderRadius(50%)
			display: flex
			align-items: center
			align-content: center
			justify-content: center
			input[type=radio]
				display: none
			label
				margin: 0px
				width: 0px
				height: 0px
				+setBorderRadius(50%)
			input[type=radio]:checked + label
				width: 100%
				height: 100%
		div.DivRadioButton + label
			margin-right: $SPACE_MEDIUM
		div.DivRadioButtonMerge
			display: flex
			align-items: center
	select.SelectMobilePhonePrefix
		max-width: $WIDTH_INPUT_MOBILEPHONE_PREFIX
		// width: $WIDTH_INPUT_MOBILEPHONE_PREFIX
		text-align: left
		margin-right: $SPACE_LITTLE
	select.InputLicensePlatePrefix
		min-width: $WIDTH_INPUT_LICENSEPLATE_PREFIX
		width: $WIDTH_INPUT_LICENSEPLATE_PREFIX
		text-align: left
		margin-right: $SPACE_TINY
	input.InputLicensePlateInfix
		min-width: $WIDTH_INPUT_LICENSEPLATE_INFIX
		// width: $WIDTH_INPUT_LICENSEPLATE_INFIX
		max-width: 500px
		text-align: center
		margin-right: $SPACE_TINY
		margin-left: $SPACE_TINY
	input.InputLicensePlateSuffix
		min-width: $WIDTH_INPUT_SQUARE
		width: $WIDTH_INPUT_LICENSEPLATE_SUFFIX
		max-width: $WIDTH_INPUT_MOBILEPHONE_PREFIX * 1.5
		text-align: center
		margin-left: $SPACE_TINY
	fieldset
		mat-form-field
			width: 100%
	div.DivCheckBoxContainer
		display: flex
		div.DivCheckBox
			margin-right: $SPACE_LITTLE
			width: $SIZE_INPUT_CHECKBOX
			height: $SIZE_INPUT_CHECKBOX
			padding: $PADDING_INPUT_CHECKBOX
			border-style: solid
			border-width: $WIDTH_BORDER_INPUTTHICK
			+setBorderRadius($RADIUS_INPUT_CHECKBOXOUTER)
			display: flex
			align-items: center
			align-content: center
			justify-content: center
			input[type=checkbox]
				display: none
			label, span
				margin: 0px
				width: 0px
				height: 0px
				+setBorderRadius($RADIUS_INPUT_CHECKBOXINNER)
			input[type=checkbox]:checked + label, input[type=checkbox]:checked + span
				width: 100%
				height: 100%
		div.DivCheckBox + label, div.DivCheckBox + span
			margin-right: $SPACE_MEDIUM
			margin-top: $SPACE_NARROW
			margin-bottom: $SPACE_NARROW
	mat-form-field
		margin: 0px
		padding: 0px
		min-width: auto
	div.DivTimer
		display: flex
		justify-content: flex-start
		select.SelectTimePrefix
			width: $SIZE_LAYOUT_PROFILEIMAGE
		span.SpanTimeInfix
			margin-top: $SPACE_SMALL
			margin-left: $SPACE_LITTLE
			margin-right: $SPACE_LITTLE
		select.SelectTimeSuffix
			width: $SIZE_LAYOUT_PROFILEIMAGE
	input.mat-input-element
			margin: 0px
			padding: 0px
			min-width: auto
div.DivMainAsideSearch, div.DivTransparantContainer
	div.DivDynamicContainer
		form
			width: auto
		input[type=button].ButtonSubmit
			width: $WIDTH_LINK_BUTTON_CONVERSATION
div.DivMainAsideSearch
	form
		margin-right: $SPACE_SMALL
		width: $WIDTH_LAYOUT_SIGNIN
		flex-shrink: 0

/* FORM - END */

/* THIRD PARTY - START */

div.DivForm, form div.DivForm fieldset
	.mat-form-field-appearance-fill, .mat-form-field-wrapper, .mat-form-field-flex, .mat-mdc-text-field-wrapper
		background-color: white !important
		+setBorderRadius($RADIUS_INPUT_GENERAL)
		border: none
		padding: 0
	.mat-form-field-underline
		display: none
	.mat-mdc-text-field-wrapper, mdc-text-field
		padding-bottom: 0px
	input.mat-date-range-input-inner, input.mat-datepicker-input
		height: auto
		font: inherit
		border: none
		outline: none
		padding: 0
		margin: 0
		vertical-align: bottom
		text-align: inherit
		+setAppearance(none)
		width: 100%
	.mat-mdc-icon-button
		width: 40px
		height: 40px
		display: inline-flex
		align-items: center
		justify-content: center
		padding: 0px

div#divMainDashboard
	.mat-form-field
		margin-bottom: $SPACE_NARROW
		margin-top: $SPACE_NARROW
		div.mat-form-field-infix
			input
				margin: 0px
				height: auto

div.DivForm
	fieldset
		width: 100%
		padding: $SPACE_LITTLE
		margin-bottom: $SPACE_LITTLE
		margin-top: $SPACE_LITTLE
		border: none
		border-radius: $SPACE_LITTLE
	.ng-select
		margin-right: $SPACE_LITTLE
		width: 100%
	.ng-dropdown-panel
		width: 100%
		padding-left: $SPACE_LITTLE
	.ng-option
		margin-top: $SPACE_TINY
	.ng-select.custom .ng-clear-wrapper .ng-clear
		font-size: 0px

agm-map
	margin: 200px
	background-color: red
	height: 500px
	width: 100%

/* THIRD PARTY - END */

// GENERAL - END


// NAVIGATION - START

// NAVIGATION - END


// SIGN IN - START

// SIGN IN - END

// RENEWAL - START

div.DivTableListContainerLeft
	div.DivTableListControlLeft
		select
			padding-top: $PADDING_INPUT_EDGE
			padding-bottom: $PADDING_INPUT_EDGE
			padding-right: $PADDING_INPUT_SIDE
			padding-left: $PADDING_INPUT_SIDE
			border-style: none
			background-repeat: no-repeat
			background-position: right
			+setAppearance(none)
	div.DivTableListControlLeft
		select
			margin-bottom: $SPACE_NARROW
			margin-top: $SPACE_NARROW
			width: 100%
			min-width: $WIDTH_INPUT_SINGLELINE
			min-height: $HEIGHT_SELECT_SINGLELINE
			font-size: $FONT_INPUT_GENERAL
			+setBorderRadius($RADIUS_INPUT_GENERAL)

// RENEWAL - END

// TABLE - START

div.DivTableContainer
	div.DivTableControl
		select
			padding-top: $PADDING_INPUT_EDGE
			padding-bottom: $PADDING_INPUT_EDGE
			padding-right: $PADDING_INPUT_SIDE
			margin-right: $MARGIN_INPUT_SIDE
			border-style: none
			background-repeat: no-repeat
			background-position: right
			+setBorderRadius($RADIUS_INPUT_GENERAL)
	div.DivTableControl
		select
			margin-bottom: $SPACE_NARROW
			margin-top: $SPACE_NARROW
			width: 100%
			padding: $MARGIN_INPUT_SIDE
			min-width: $WIDTH_INPUT_SINGLELINE
			min-height: $HEIGHT_SELECT_SINGLELINE
			padding-right: inherit
			font-size: $FONT_INPUT_GENERAL
			border-style: none
			background-repeat: no-repeat
			+setBorderRadius($MARGIN_INPUT_SIDE)
		input
			font-size: $FONT_INPUT_GENERAL
	div.DivConversationStatus
		select
			font-size: $FONT_SELECT_SEVERITY
			width: $WIDTH_LABEL_CONVERSATIONSTATUS
			+setBorderRadius($RADIUS_TICKET_STATUS)
	div.DivConversationContent
		textarea
			+setBoxSizing(border-box)
			width: 100%
			min-width: $WIDTH_INPUT_SQUARE
			padding: $PADDING_INPUT_SIDE
			font-size: $FONT_INPUT_GENERAL
			border-style: none
			+setBorderRadius($RADIUS_INPUT_GENERAL)

// TABLE - END

// BRINS-WEBSITE - START

div#divMainDashboard
	div#divBodyFooterContainer
		div.DivFooterInfoPage
			input
				width: 100%
				height: $SPACE_BIG
				border-radius: ($SPACE_SMALL + $SPACE_MEDIUM)
				padding: $SPACE_LITTLE $SPACE_HUGE
				background-color: #FFFFFF
				font-size: 1.1em
				border-style: none
			button
				position: absolute
				right: ($PADDING_INPUT_CHECKBOX + $SPACE_THIN)
				top: $SPACE_TINY
				bottom: $SPACE_TINY
				border: 0
				color: #fff
				outline: none
				width: ($SIZE_LINK_BUTTONATTACHMENT * 3)
				height: ($SIZE_LINK_BUTTONATTACHMENT * 3)
				padding: 0 $SPACE_LITTLE
				margin-left: -$SPACE_BIG
				border-radius: $SIZE_TABLE_THUMBNAILSICONSMALL
				z-index: 2
	form
		div.DivForm
			fieldset
				input, inputarea, select
					height: 55px
					margin-bottom: 20px
				textarea
					height: 220px
					resize: vertical
				textarea.TextareaEditorial
					height: 155px
				input.inputAttachment
					width: 300px
					+setBorderRadius(10px)
				input[type=button].ButtonAttachmentEditorial
					height: auto
					padding: 0px
					background-size: contain
			input.inputButton
				display: block
				width: 300px
				margin: 20px auto
				+setBorderRadius(30px)

div.DivConversationMelisa
	div.DivConversation
		div.DivConversationSendMessage
			textarea, textarea:hover, textarea:active
				height: 20px
				width: 180px
				padding: 10px
				overflow-y: hidden
				margin: auto 10px
				margin-right: 0px
				+setBorderRadius(20px)
			textarea, input[type=image]
				outline: none
			input[type=image]#imageAttach
				width: 35px
				height: 35px
				display: block
				margin: auto 0px
			input[type=image]#imageSend
				width: 20px
				height: 20px
				padding: 10px
				+setBorderRadius(20px)
				margin: auto 0px


// BRINS-WEBSITE - END
