/* @charset "utf-8"; */
/* CSS Document */

// IMPORT - START

@import form-initialization-general

// IMPORT - END


// SCREEN WIDTH 1600px - START

@media screen and (max-width: 1600px)
	div#divBodyDashboardBRINSUpdate
		div.DivContentCorporateSlider
			div.DivContentImageCorousel
				section#sectionImageCorousel
					width: 35vh
					height: 35vh

// SCREEN WIDTH 1600px - END


// SCREEN WIDTH 1450px - START

@media screen and (min-width: 1450px)
	div.DivTableContainer
		ul.ListTableConversation
			li
				div.DivConversationGeneral
					div.DivConversationHeader
						div.DivConversationSubHeader
							span.SpanConversationTitle
								max-width: initial !important

// SCREEN WIDTH 1450px - END


// SCREEN WIDTH 1424px - START

@media screen and (max-width: 1424px)
	div.DivTableContainer
		ul.ListTableConversation
			li.LiConversationContainerRight, li.LiConversationContainerLeft
				div.DivConversationHeader
					div.DivConversationProfile
						display: none
	div#divBodyDashboardBRINSUpdate
		div.DivContentCorporateSlider
			div.DivContentImageCorousel
				section#sectionImageCorousel
					width: 33vh
					height: 33vh

// SCREEN WIDTH 1424px - END


// SCREEN WIDTH 1390px - START

@media screen and (max-width: 1390px)
	div#divBodyDashboard, div#divBodyRelationshipManagerDetail
		div.DivContainerHorizontal, div.DivContainerVertical
			flex-direction: column
			div.DivContainer
				margin-top: $SPACE_SMALL
				margin-right: 0px
				margin-left: 0px
				margin-bottom: $SPACE_SMALL
			div.DivFormVerticalContainer
				flex-direction: row
				justify-content: flex-start

	div#divMainDashboard
		div.DivBodyGeneral
			div.DivContentCorporate
				div.DivContentCorporateDescription
					p
						font-size: 12pt !important
				div.DivContentCorporatePageImage
					height: 300px !important
					img
						height: 300px !important
					h1
						font-size: 90pt

	div#divBodyDashboardAboutBRINS
		p
			font-size: 2.3em

	div#divBodyDashboardAward
		div.DivContentAwardsDescription
			p
				font-size: 12pt

	div#divBodyDashboardBRINSUpdate
		p
			font-size: 12pt
		div.DivContentDescription
			h1
				font-size: 1.8em

	div#divBodyDashboardPartners
		div#divContentBRIGroup
			h1
				margin-top: 0px !important
			section#sectionImageGallery
				height: 110px !important

	div#divBodyFooterContainer
		div.DivFooterInfoPage
			padding-left: 20px
			padding-right: 20px
			margin: auto 0
			box-sizing: border-box
			div.DivFooterPageHorizontalContainer
				div.DivFooterSectionPage
					div#divFooterSubscribeContainer
						margin-top: 0px
			div.DivFooterVerticalContainer
				div.DivFooterHorizontalContainer
					margin-bottom: 5px

// SCREEN WIDTH 1390px - END


// SCREEN WIDTH 1340px - START

@media screen and (max-width: 1340px)
	div#divBodyDashboardBRINSUpdate
		div.DivContentCorporateSlider
			div.DivContentImageCorousel
				section#sectionImageCorousel
					width: 28vh
					height: 28vh

// SCREEN WIDTH 1340px - END


// SCREEN WIDTH 1250px - START

@media screen and (max-width: 1250px)
	div#divBodyDashboard1
		div.DivContentDashboard
			div.DivContentSection
				div.DivContentTitle
					h3
						font-size: 3em
	div#divBodyDashboardBRINSUpdate
		div.DivContentCorporateSlider
			div.DivContentImageCorousel
				section#sectionImageCorousel
					width: 25vh
					height: 25vh

// SCREEN WIDTH 1250px - END


/* SCREEN WIDTH 1140px - START */

@media screen and (max-width: 1140px)
	div#divBodyHome main
		padding: $SPACE_SMALL

	div#divBodyHome img#imageLogoHeader, img#imageLogoHeader, input[type=button]#buttonNavigation
		top: $SPACE_SMALL

	div#divBodyHome img#imageLogoHeader, img#imageLogoHeader
		right: $SPACE_SMALL

	input[type=button]#buttonNavigation
		left: $SPACE_SMALL

	div.DivMainAsideSearch
		flex-direction: column
		form
			width: 100%
		div.DivDynamicContainer
			margin-left: 0px
			div.DivSubDynamicContainer
				margin-top: 0px

	div.DivContainerListHorizontal
		div.DivListContainer
			form
				div.DivForm
					fieldset
						div.DivFormListHorizontalContainer
							padding-left: $SPACE_LITTLE
							flex-direction: column
							div.DivForm2Column
								width: 100%
							div.DivRoundButtonContainer
								display: none
				input[type=button]#inputButtonSubmit
					display: block

	div.DivForm
		padding-left: $SPACE_SMALL
		padding-right: $SPACE_SMALL

	div.DivTableContainer
		margin-left: 0px

	+generateKeyFrames(animationNavigationShow)
		0%
			margin-left: $SPACE_NAVIGATION_HIDE
			+setOpacity(0.0)
		100%
			margin-left: 100px
			+setOpacity(1.0)

	+generateKeyFrames(animationNavigationHide)
		0%
			margin-left: 100px
			+setOpacity(1.0)
		100%
			margin-left: $SPACE_NAVIGATION_HIDE
			+setOpacity(0.0)

	div.DivTableContainer
		ul.ListTableConversation
			li.LiConversationContainerRight, li.LiConversationContainerLeft
				div.DivConversationHeader
					div.DivConversationProfile
						display: block

	div#divBodyDashboardBRINSUpdate
		div.DivContentCorporateSlider
			div.DivContentImageCorousel
				section#sectionImageCorousel
					width: 24vh
					height: 24vh

// SCREEN WIDTH 1140px - END


// SCREEN WIDTH 1100px - START

@media screen and (max-width: 1100px)
	div.DivBodyGeneral
		scroll-snap-align: unset
		position: relative

	div#divMainDashboard
		select, option
			font-size: 1.1em
		div.DivModal
			height: 500px
			div.DivModalHeader
				height: 60px
				h2
					font-size: 1.3em
			div.DivModalFigureContainer
				figure
					width: auto
					height: auto
					iframe
						height: 400px
						margin: 0

	div#divBodyDashboard1
		div.DivContentDashboard
			div.DivContentSection
				margin-top: 100px
				margin-bottom: 20px
				margin-left: 20px
				margin-right: 20px
				div.DivContentTitle
					h3
						font-size: 2em

	div#divBodyDashboardAward
		div#divMainAwards
			padding: 100px 50px
			box-sizing: border-box
		div.DivContentAwardsTitle
			height: 50px
		div.DivContentAwardsContent
			flex-direction: column
			align-items: center
			justify-content: space-between
			hr
				display: none
				width: 0px
			div.DivVerticalLine
				display: block
				border-left: 6px solid  green
				position: absolute
			div.DivContentCalenderDetail
				top: 0px
				margin-top: 150px
				width: -webkit-fill-available

	div#divBodyDashboardBRINSUpdate
		div.DivContentCorporateSlider
			display: flex
			flex-direction: column
			justify-content: space-between
			div.DivContentCorporateTitle
				display: flex
				flex-direction: column
				height: 100%
				align-items: center
				div.DivContentTextDetail
					width: 100%
					p
						text-align: center
			div.DivContentImageCorousel
				margin-top: 20px
				padding: 0px
			div.DivContentDescription
				display: flex
				flex-direction: column
				padding: 0px
				h1
					text-align: center
					font-size: 1.2em
				p
					font-size: 1em
				div.DivContentBrinsUpdate
					p
						-webkit-line-clamp: 3

	div#divBodyDashboardAboutBRINS
		div.DivContent
			margin-top: 100px
			margin-bottom: 20px
			margin-left: 20px
			margin-right: 20px
			div.DivContentTitle
				display: flex
				flex-direction: column
				margin-bottom: 0px
				hr
					margin: 20px 0px 20px 0px
					height: 0px
					background: #FFFFFF
			div.DivContentDescription
				height: unset
				padding-bottom: 20px
			div.DivContentButtonDetail
				text-align: left
				margin-top: 20px
				margin-left: 0px
		h1
			font-size: $FONT_LABEL_H3
			font-weight: 600
			margin-right: 0px
			margin-left: 0px
			letter-spacing: 5px
			width: 100%
		hr
			width: 100%
		p
			font-size: 1.75em
			text-align: justify-all
			margin-left: 0px
			height: 100%

	div#divBodyDashboardPartners
		div.DivContentPartners
			margin-left: 20px
			margin-right: 20px
			height: 100%
			div.DivContent2Column
				display: flex
				margin: 20px
				height: 100%
				align-items: center
				flex-direction: column
				div.DivContent
					margin: 0px
					h2
						text-align: center
					p
						text-align: left
					img
						display: none
			div.DivContent:nth-child(odd)
				width: 100%
			div.DivContent:nth-child(even)
				width: 100%
			div.DivContentShapeDescription
				display: none
			img#imageIconPartners
				display: none
		div.DivContent
			margin-left: 20px
			margin-right: 20px
			h1
				margin-top: 0px
			div.DivContentImageGallery
				div.DivButtonNavigate
					img
						width: 10px
						height: 10px
						padding: 15px
				section#sectionImageGallery
					label.labelImagePrevious, label.labelImageNext
						display: none
					label.labelImageActive
						img
							width: 200px

	// BRINS UPDATE AND ABOUT MENU - START

	div#divBodyAbout
		font-size: 90%
		margin-bottom: 0px
		div.DivContentMain
			display: flex
			height: auto
			width: auto
			flex-direction: row
			padding: 15% 50px 0px 50px
			div.DivContentAbout
				display: flex
				flex-direction: column
				margin: 0
				width: 100%
				height: 100%
				align-self: flex-start
				h1
					margin-top: 10px
				div.DivHeaderAbout, div.DivAboutHeaderTitle
					text-align: center
			div.DivContentDescription
				width: 100%
				margin-top: 0px
				margin-bottom: 0px
				p
					padding: 0
					font-size: 1.1em
					line-height: 30px
					text-align: left
			div.DivContentSearch
				padding: 10px
				width: 90%
				div.DivContentSearchDetailFilter
					input[type=text]
						width: auto
			div.DivContentButtonAbout
				div.DivContentButtonDetail
					a.ButtonForward
						left: 0px
			h1
				font-size: 2.5em
				margin-left: 0
				display: flex
				flex-direction: column
				justify-content: flex-start
			p
				font-size: 1.2em
				font-weight: 550
		div.DivContentImage
			display: flex
			position: relative
			width: 100%
			height: 100%
			flex-direction: column
			align-items: center
			figure
				margin: 0
				display: flex
				flex-direction: column
				padding-right: 0
				align-items: flex-end
				img:nth-of-type(1)
					position: inherit
					left: 0px
					top: 0px
					height: 300px
					width: 300px
					border-radius: 25px
				img:nth-of-type(2), img:nth-of-type(3), img:nth-of-type(4), img:nth-of-type(5)
					display: none
		div#divContentWhatBRINS
			display: flex
			flex-direction: column
			height: 100vh
			div.DivContentAbout
				display: flex
				margin: 20px
				height: 100%
				flex-direction: column
				p.DivContentSectionInactive
					display: none
				p.DivContentSectionActive
					display: block
				p
					height: 100%
					font-size: 15pt
					text-align: center
				h1
					font-size: 2.8em
		div#divBodyMissionAndVission
			height: 100vh
			div.DivContentColumn
				display: flex
				flex-direction: column
				margin: 20px
				background-color: red
				align-items: center
				div.DivContentImage
					img
						width: 35vh
						object-fit: contain
					img.ImageShapeCEO
						position: absolute
						z-index: -1
						width: 100%
						height: 100%
	div#divBodyHeadlines
		padding: 0px 50px
		div.DivBodyFloatMain
			margin: 0 !important
			flex-direction: column
			margin: 0px 50px
			section:nth-child(1), section:nth-child(2)
				width: 100% !important
			section:nth-child(1)
				div.DivSectionHeadlines
					margin-right: 0 !important
					div.DivContentNewsVertical
						div.DivContentNewsDetail
							height: auto
			section.sectionNewsFeed, section.sectionHeadlines
				div.DivContentTitleHorizontal
					div.DivContentButtonDetail
						display: none
					input[type=button].ButtonResponsive
						display: flex !important
						box-sizing: border-box
						border-radius: 25px
						border: none
						padding: 10px 20px
						font-family: "Poppins-SemiBold"
						color: white
						background-color: #064F89
						align-self: center
						height: 40px
				div.DivSectionNewsFeed, div#divTestimonyDetail, div.DivContentCommunity
					display: grid !important
					grid-template-columns: repeat(2, 1fr)
					grid-column-gap: 30px
					div.DivContentNewsHorizontal
						grid-template-columns: 140px 1fr
						img
							width: 140px
			section.sectionHeadlines
				div#divTestimonyDetail
					div.DivContent
						width: 100%
						div.DivContentTestimony
							margin-right: 0
							height: auto
	div#divFinancialLiteracy
		font-size: 0.7em
		margin: 50px 0px !important
		padding: 0px 50px
		height: auto !important
		div.DivFinancialLiteracy
			padding: 25px 30px !important
			height: 250px
			box-sizing: border-box
			div.DivFinancialParagraphButton
				height: 150px
				display: flex !important
				flex-direction: column
				justify-content: flex-start
				align-items: flex-start
				margin-left: 0 !important
				p
					font-size: 1.1em
				input[type=button]
					-webkit-appearance: none
					box-sizing: border-box
					border-radius: 25px
					border: none
					padding: 10px 30px
					font-family: 'Poppins-SemiBold'
					color: white
					background-color: #F38F1E
			div.DivContentButtonDetail
				display: none
		div.DivFinancialLiteracyImage
			img
				height: 250px !important
	div#divBodyTabAbout
		div.DivContentMain
			div.DivContentTabAbout
				button
					width: 250px
					font-size: 0.9em !important
		div.DivContentDownload
			margin-top: 15px
			div.DivContentDownloadMedium
				+setBorderRadius(20px)
				img
					width: 120px
				div.DivContentDownloadDescription
					label
						font-size: 1.1em
						line-height: 24px

	// BRINS UPDATE AND ABOUT MENU - START


	// NEWSFEEDS BRINS UPDATE - START

	div#divMainBRINSUpdateArticle
		img.ImageHeaderArticle
			width: 100%
		div.DivBRINSUpdateArticleDetail
			margin: 30px 35px
			div.DivContentHorizontal
				flex-direction: column

	div#divBodyContentMilestone
		padding: 0px 30px 25px 30px
		div.DivContent2Column
			display: grid
			grid-template-columns: repeat(2, 1fr)
			grid-gap: 10px
			font-size: 0.9em
			div.DivContentAwards
				width: 100%
				margin: 0
				img
					width: 160px
					height: 100%
				div.DivContentAwardsDetail
					width: 100%
					height: 185px
					h5
						display: -webkit-box
						-webkit-line-clamp: 3
						-webkit-box-orient: vertical
						overflow-y: hidden

	// NEWSFEEDS BRINS UPDATE - START


	// TESTIMONY BRINS UPDATE - START

	div#divTestimonyContainer
		padding: 0px 25px
		margin: 0 !important


	// TESTIMONY BRINS UPDATE - END


	// FINANCIAL LITERACY BRINS UPDATE - START

	div#divFinancialLiteracyContainer
		margin: 0 !important
		padding: 0px 25px
		font-size: 90%
		section.Section2Column
			div.DivSectionContent
				div.DivContentNewsVertical
					img, div.DivContentNewsDetail
						height: 210px !important
					div.DivContentNewsDetail
						div.DivContentNewsParagraph
							display: flex
							flex-direction: column
							justify-content: space-between
							div.DivParagraphInnerHTML
								p
									display: -webkit-box
									-webkit-line-clamp: 3
									-webkit-box-orient: vertical
									overflow-y: hidden
		section.Section4Column
			display: grid !important
			grid-template-columns: repeat(auto-fill,minmax(230px,1fr))
			grid-auto-flow: column
			grid-auto-columns: minmax(230px,1fr)
			grid-gap: 20px
			overflow-x: auto
			div.DivSectionContent
				width: 100% !important

	// FINANCIAL LITERACY BRINS UPDATE - END


	// COMMUNITY BRINS UPDATE - START

	div#divCommunityContainer
		margin: 0 !important
		padding: 0px 50px !important
		section.Section2Column
			div.DivContent
				figure
					flex-direction: column
					text-align: center
					margin-bottom: 60px
					div.DivImageBackground
						img
							height: 200px
					figcaption
						padding: 0
						h3
							margin-top: 40px
						p
							text-align: center
							font-size: 1.1em
							line-height: 30px

	// COMMUNITY BRINS UPDATE - END


	// FOOTER - START

	div#divMainLanding, div#divMainDashboard
		div#divBodyFooterContainer
			height: 100%
			scroll-snap-align: unset
			div.DivFooterInfoPage
				display: flex
				flex-direction: column
				justify-content: unset
				padding-top: 50px
				padding-left: 20px
				padding-right: 20px
				padding-bottom: 50px
				align-items: center
				div.DivFooterVerticalContainer
					width: 100%
					display: flex
					flex-direction: column
					align-items: center
					label
						display: block
						text-align: center
						font-family: 'Poppins-Regular' !important
						font-size: 1.2em
					p
						text-align: center
						font-size: 0.8em
					div.DivFooterContentContainer
						div.DivFooterSVGContainer
							display: none
					div.DivFooterHorizontalContainer
						display: flex
						justify-content: center
						&:first-of-type
							margin-top: -5px
						div.DivFooterSVGLargeContainer, div.DivFooterSVGMediumContainer
							img
								margin-top: 0px !important
					div.DivFooterSocialMedia
						margin-top: 0px
						margin-bottom: 40px
						div.DivFooterHorizontalContainer
							justify-content: flex-start
							margin: auto
							div.DivFooterSVGContainer
								width: 60px
								img
									display: block
									width: 30px
									height: 30px
									margin: 10px 20px
				div#divSectionHQContactUs
					display: grid
					grid-template-columns: repeat(2, 1fr)
					text-align: center
					div.DivFooterHQLocation
						div.DivFooterContentContainer
							margin: 0 !important
							margin-bottom: 40px !important
				div.DivFooterPageHorizontalContainer
					width: 100%
					div.DivFooterSectionPage
						display: flex
						flex-direction: column
						div.DivFooterLink
							div.DivFooterContentContainer
								width: 100%
								div.DivParagraphLink
									display: grid
									grid-template-columns: repeat(2, 1fr)
									width: 100%
						div#divFooterSubscribeContainer
							width: 97%
							margin-bottom: 10px
							margin-top: 10px
							div.DivFooterVerticalContainer
								width: auto
								div.DivFormSubscribeEmail
									width: 100%
									font-size: 90%
			div.DivFooterCopyright
				display: none
			div.DivFooterCopyrightResponsive
				display: flex
			div.DivFormSubscribeEmail
				margin-top: 15px !important
				margin-bottom: 15px !important

	// FOOTER - END


	// START FAQ

	div#divMainTermsAndCondition
		div.DivHeaderPolicy
			height: auto
			img#imgHeaderShape
				display: none
			img#imgHeaderResponsive
				display: block
			h1
				font-size: 2em
		div.DivSiteContainer
			margin: 10px 40px
			section
				h1, h3
					margin-top: 0
					margin-bottom: 20px
					font-size: 1.5em

	// END FAQ


	// START - ABOUT US

	div#divMainDashboard
		div#divBodyAbout
			div.DivContentMain
				div.DivContentAbout
					div.DivContentButtonAbout
						height: 90px
						width: 100%
					div.DivContentButtonDetail
						a.ButtonPlay
							padding: 10px 5px
							svg
								width: 10px !important
								height: 10px !important
								margin-left: 12px
								margin-bottom: 0px
							span
								margin-left: 20px
								font-size: 1em
						a.ButtonPlay:before
							width: 35px
							height: 35px
							border: 3px solid #064F89
						input[type=button].ButtonResponsive
							position: absolute
							display: flex !important
							box-sizing: border-box
							border-radius: 25px
							border: none
							padding: 10px 20px
							font-family: "Poppins-SemiBold"
							color: white
							background-color: #064F89
							align-self: center
							height: 40px
						.ButtonForward
							display: none
						.ButtonForwardOutlook
							display: block !important
			div#divContentWhatBRINS
				height: 340px
				padding: 0 20px
				div.DivContentAbout
					p
						font-size: 1.1em
						line-height: 30px
						text-align: justify
					h1
						font-size: 2em
						font-family: "Poppins-Bold"
						font-weight: bold
						text-align: left
		div#divBodyMissionAndVission
			div.DivContent2Column
				margin: 30px
				div.DivContentImage
					img.ImageShapeCEO
						display: none
					img.ImageCEO
						position: relative
						width: 300px
						bottom: 0
						margin-bottom: 20px
				div.DivContent
					p
						font-size: 1.2em
						width: 280px !important
					h1
						font-size: 2em
			div.DivContentDescription
				margin: 30px
				height: 270px
				div.DivContentVisionDescriptionDetail, div.DivContentMissionDescriptionDetail
					padding: 5px
					box-sizing: border-box
					img
						width: 55px
						height: 55px
						margin: 0
						margin-top: 10px
					h1
						margin: auto 0px
						font-size: 1.8em
				div.DivContentVisionDescriptionParagraph, div.DivContentMissionDescriptionParagraph
					margin-left: 40px
					margin-right: 40px
				div.DivContentVisionDescriptionInactive, div.DivContentMissionDescriptionInactive
					h1
						margin: auto !important
				div.DivContentMissionDescriptionActive, div.DivContentVisionDescriptionActive
					div.DivContentMissionDescriptionDetail, div.DivContentVisionDescriptionDetail
						margin-top: 10px
					div.DivContentMissionDescriptionParagraph, div.DivContentVisionDescriptionParagraph
						margin-left: 20px
						margin-right: 30px
						overflow: scroll
						p
							font-size: 1em
							line-height: 24px
						ol.OrderedNumber
							margin-left: 20px
							margin-top: 0
							margin-bottom: 0
							p, li::marker
								font-size: 1em
								line-height: 25px
								margin-top: 0
								margin-bottom: 0
			div#divContentVisionMissionBRINS
				height: 340px
				font-size: 90%
				div.DivContentVisionDescription
					div.DivContentVisionDescriptionDetail
						padding: 10px
					div.DivContentVisionDescriptionParagraph
						margin: 0 30px
				div.DivContentMissionDescription
					div.DivContentMissionDescriptionDetail
						padding: 10px
		div#divBodyInformation
			flex-direction: column
			div.DivContentMain, div.DivContentDescription
				width: 100%
				padding: 20px
				box-sizing: border-box
			div.DivContentMain
				padding-bottom: 0
				img.ImageIconCircuit
					display: none
				div.DivContentTextMain
					top: 0
					margin: auto 20px
					img.ImageShapeContentText
						display: none
					h1
						font-size: 2em
						margin: 20px auto

					p
						font-size: 15.84px !important
						line-height: 30px
			div.DivContentDescription
				padding-top: 0
				div.DivContentBoxDetail
					height: 315px
					margin: 15px auto
					+setBorderRadius(25px)
					img
						width: 120px
						height: 120px
					div.DivContentBoxDescription
						margin: 0 20px
						h4
							font-size: 1.3em
						div.DivContentBoxText
							p
								font-size: 0.9em
								line-height: 21px
								text-align: left
					div.DivContentButtonDetail
						margin-left: 20px
						font-size: 0.8em
						height: 0
						input[type=button].ButtonResponsive
							position: absolute
							display: flex !important
							box-sizing: border-box
							border-radius: 25px
							border: none
							padding: 10px 20px
							font-family: "Poppins-SemiBold"
							color: white
							background-color: #064F89
							align-self: center
							height: 40px !important
						div.ButtonForward
							display: none
				div.DivContentBoxDetail:nth-child(odd)
					margin-left: 0
					margin-right: 15px
				div.DivContentBoxDetail:nth-child(even)
					margin-left: 15px
					margin-right: 0
		div.DivTabContainer
			margin: auto 20px
			div.DivContentTabAbout
				width: 100%
				display: flex
		div#divBodyStructureOrganization
			div.DivContentMain, div.DivContentDescription
				padding: 20px 30px
			div.DivContentMain
				h1
					margin: 0
					font-size: 2em
			div.DivContentDescription
				padding: 20px 30px
				h3
					font-size: 1.2em
					margin-bottom: 0
				h4
					font-size: 1.1em
					margin-bottom: 0
				p
					font-size: 1em
					line-height: 26px
				img
					width: 250px
					margin-top: auto
					margin-bottom: auto
			div.DivContentMainDivision, div.DivContentDescriptionDivision
				margin: 20px 40px
				img
					width: 300px
					margin: 30px 0
				h1
					font-size: 2em
			div.DivContentDescriptionDivision
				justify-content: space-between
				display: grid
				grid-template-columns: 1fr 1fr
	div#divBodyContentMilestone
		h1
			font-size: 1.8em
		div.DivContentHorizontal
			div.DivContentMilestoneDetail
				width: 440px
				height: 580px
				img
					width: 400px
					height: 290px
					margin: auto
				div.DivContentPoint
					padding: 10px
					p.ParagraphCenter
						width: 100%
						font-size: 1.1em
						line-height: 28px
			div.DivContentMilestoneDetail:hover
				img
					width: 440px
					height: 300px
					margin: auto
		div.DivContent2Column
			div.DivContentAwards
				div#divContentAwardsDetail
					height: 165px
					label
						margin: 10px
					h5
						margin: 0 10px
					a.ButtonForward
						position: relative
						bottom: 0px
						right: 0px
						margin-left: auto
						margin-right: 10px
						margin-top: auto
						margin-bottom: 10px
						span
							font-size: 1em

	// START - PARTNER

	div#divBodyPartnerAndClient
		margin: 40px
		h1
			font-size: 2em
			margin: 10px
		div.DivContentDescription
			div.DivContentBoxDetail
				div.DivContentShapeDescription
					img:first-of-type
						height: 130px
						width: 130px
					img:last-of-type
						width: 90px

	// END - PARTNER

	// END - ABOUT US


	// CUSTOMER CARE - START

	div#divContentCheckPolicy, div#divContentPartnerWorkshop, div.DivBodySiteContainer
		padding: 25px 50px
	div#divContentCheckPolicy, div#divContentPartnerWorkshop
		div.DivContent2Column
			flex-direction: column
			margin: 0
			div.DivContent
				width: 100%
				box-sizing: border-box
			div.DivContentForm
				margin: 0
				form
					margin-bottom: 0
					div.DivContentNewsVertical
						margin: 0
	div#divContentCheckPolicy
		div.DivContent2Column
			div.DivContent
				&:nth-child(2)
					padding: 30px 50px
		input[type=button].ButtonResponsive
			font-size: 1em !important
	div#divContentPartnerWorkshop
		div.DivContent2Column
			div#divContentBoxDetailClaim
				padding: 0
				img
					width: 80px
					margin-left: 20px
				div.DivContentBoxDescription
					height: 100px
				div.DivContentButtonDetail
					margin: 25px auto
			div.DivContent2Column
				display: grid
				grid-template-columns: repeat(2, 1fr)
				padding: 0
				grid-gap: 30px
				margin: 10px 0px
			&:nth-child(2)
				flex-direction: column-reverse
				div.DivContentLocationMaps, div.DivContentPartnershipWorkshop
					width: 100%
				div.DivContentPartnershipWorkshop
					margin: 0
					div.DivContentDescription
						margin: 40px 0 20px 0
						h2, p
							text-align: left
						div.DivContentButtonDetail
							div.ButtonForward
								margin-left: 0
	div#divContentTicketAndCallCenter
		div.DivContent2Column
			grid-template-columns: 1fr !important
			div.DivContent
				div.DivContentForm
					form
						padding: 0px 50px
						box-sizing: border-box
				div.DivContentDescription
					display: grid
					grid-template-columns: repeat(3, 1fr)
					grid-gap: 30px
					margin: 20px !important
					div.DivContentDetail
						margin: 0 !important
						img
							height: 80px !important
	div#divContentCheckPolicy, div#divContentPartnerWorkshop, div#divContentTicketAndCallCenter
		input[type=button].ButtonResponsive
			display: flex !important
			box-sizing: border-box
			+setBorderRadius(50px)
			border: none
			padding: 10px 30px
			font-family: "Poppins-SemiBold"
			color: white
			background: #064F89
			align-self: center
			// width: auto !important
			height: 55px
			margin-left: auto
			margin-right: auto
			margin-bottom: 20px
			font-size: 1.2em
			-webkit-appearance: none
		div.DivContentButtonDetail
			display: none

	// CUSTOMER CARE - END


	// PARTNERSHIP WORKSHOP - START

	div#divCustomerCareWorkshop, div#divContainerPartnershipWorkshop
		padding: 10px 50px
	div#divContainerPartnershipWorkshop
		hr.HeadingBorder
			display: none
		div.DivContent2Column
			div.DivContentLocation
				width: 100%
				div.DivContent
					box-sizing: border-box
					margin-top: 20px
					width: 100%
					display: grid
					grid-template-columns: repeat(3, 1fr)
					height: 100%
					align-items: flex-start
					div.DivContentLocationDetail
						p
							font-size: 15.84px !important
			div.DivContentLocationMaps
				margin: 20px 0 0 0
				width: 100%
				div.DivFormNoBorder
					margin: 20px 0 0 0
					form
						div.my-google-map
							margin-right: 0
	div#divTableContainerPartnershipWorkshop
		table.TableBox
			thead, tbody
				tr
					td, th
						padding: 10px
						font-size: 0.8em
						div.DivContentButtonDetail
							a.ButtonForwardTable::before
								width: 50px
								height: 50px
							a.ButtonForwardTable
								padding: 15px 10px
					td:nth-child(1)
						width: 5%
					td:nth-child(2)
						width: 15%
					td:nth-child(3)
						width: 35%
					td:nth-child(4)
						width: 10%
					td:nth-child(5)
						width: 15%
					td:nth-child(6)
						width: 20%

	// PARTNERSHIP WORKSHOP - END

	// COMPANY PROFILE - START

	div#divMainDashboard
		div#divBodyContentOrdered
			margin: 40px
			h1
				margin-top: 0
				margin-bottom: 0
				font-size: 2em
			p
				font-size: 1em
				line-height: 26px
			div.DivContent2Column
				margin: auto
			div.DivContentMain
				figure
					iframe
						height: 400px

	// COMPANY PROFILE - END


	// CORPOTATE VALUE - START

	div#divMainDashboard
		div#divBodyContentOrdered
			div#divContent2ColumnBrillianWays
				flex-direction: column
				div.DivContent
					div.DivContentOrderedListDescription
						margin-right: 0
						margin-bottom: 10px
						h1, p
							text-align: left
						p
							margin: 5px 0
						h1
							font-size: 1.5em
			div#divContent2ColumnCoreValue
				flex-direction: column-reverse
				div.DivBodyContentOrderedList
					div.DivContentOrderedListImage
						margin: 15px 0px
						margin-right: 20px
					div.DivContentOrderedListDescription, div.DivContentOrderedListImage
						margin-top: auto
						margin-bottom: auto
				h2.headingFirstLetterBig, h2.headingFirstLetterBig::first-letter
					font-size: 1.5em
					font-family: 'Poppins-SemiBold'
			div#divContent2ColumnCoreValue, div#divContent2ColumnBrillianWays
				div.DivContent, div.DivContentImage
					width: 100%
				div.DivContentImage
					margin-top: 20px
					margin-bottom: 30px
					img
						width: 100%

	// CORPORATE VALUE - END


	// SOCIAL VALUE - START

	div#divBodySocialValueCEO
		margin: 0 !important
		font-size: 90%
		div.DivContent2Column
			display: grid
			grid-template-columns: 0.7fr 1fr
			margin: 0 !important
			grid-column-gap: 20px
			div.DivContentImage
				width: 100%
				margin: 0 !important
				img.ImageShapeCEO
					left: unset !important
					top: 21%
					bottom: 0
					margin: auto 0 auto 0 !important
			div.DivContent
				width: 100% !important
				div.DivContentMessageCEO
					margin-right: 0
					text-align: center !important
					p
						text-align: center
						line-height: 30px
						font-size: 1.1em
	div#divBodySocialValueInformation
		padding: 0px 50px !important
		margin: 0 !important
		flex-direction: column
		div#divContainMainHeader
			display: grid !important
			grid-template-columns: repeat(4, 1fr)
			grid-column-gap: 15px
			font-size: 90%
			width: 100% !important
			div.DivContentBoxDetail
				width: 100% !important
				height: 170px !important
				margin: 0 !important
				padding: 10px
				box-sizing: border-box
				div.DivContentDetail
					img
						width: 100px !important
					div.DivContentBoxDescription
						width: 100% !important
						font-size: 80%
						height: 50px
		div.DivContentDescription
			width: 100% !important
			margin-top: 40px
			div#divBodyTabAbout
				div.DivContentMain
					h3
						text-align: center
					div.DivContentTabAbout
						box-sizing: border-box
	div#divBodySocialValueInformation, div#divBodySocialValueCSR
		div.DivContentDescription
			h1
				font-size: 2em !important
			div.DivContentMain
				p
					font-size: 1.1em
					line-height: 24px
				ol.OrderedNumber
					margin-left: 30px
	div#divBodySocialValueCSR
		div.DivContentMain
			display: grid !important
			grid-template-columns: repeat(2, 1fr)
			grid-column-gap: 20px
			div.DivContentBoxDetail
				width: 100% !important
				height: 100% !important
				margin: 0 !important
				div.DivContentBoxDescription
					height: 80px !important
	div#divBodyDashboardBRINSUpdate
		div#divSocialValueBRINSUpdate
			div.DivContentCorporateTitle
				h1
					font-size: 2em
			div.DivContentImageCorousel
				overflow: hidden
				padding: 20px 0px 40px
				margin-bottom: 10px
				section#sectionImageCorousel
					width: 29vh
					height: 29vh
			div.DivContentDescription
				p
					font-size: 1.1em !important

	// SOCIAL VALUE - END


	// LANDING - START

	div#divMainLanding
		overflow-x: hidden
		input[type=button].ButtonResponsive, input[type=button].ButtonResponsive2
			display: flex !important
			box-sizing: border-box
			+setBorderRadius(30px)
			border: none
			padding: 10px 20px
			font-family: "Poppins-SemiBold"
			color: white
			align-self: center
			height: 40px
			margin-left: auto
			margin-right: auto
		input[type=button].ButtonResponsive
			background-color: #064F89
		input[type=button].ButtonResponsive2
			background-color: #F7931E
		div.DivContentButtonDetail
			display: none
			-webkit-appearance: none
		div#divBodyDashboard1
			input[type=button].ButtonResponsive
				height: 25%
			div.DivPage
				flex-grow: 3
				margin-bottom: 70px
		div#divBodyDashboardBussinesPillars
			div.DivContentLayoutContent
				div.DivContentCorporate
					margin: 0 !important
					div.DivContentCorporatePage
						div.DivContentCorporatePageImage
							margin-top: 50px
							div.DivClipWrap
								img
									width: 100%
									object-fit: contain
							h1
								font-size: 7em
					div.DivContentCorporateDescription
						p
							height: 90px
							font-size: 13pt !important
			div.DivContentLayoutSide
				display: inline-flex
				justify-content: center
				flex-direction: column
				span
					margin: 3px 0
				span.active
					width: 12px
					height: 45px
		div#divBodyDashboardBussinesPillars
			height: 100%
			padding: 100px 50px
			box-sizing: border-box
			div.DivContentLayoutSide
				margin: 20px
		div#divBodyDashboardAboutBRINS
			height: 100%
			input[type=button].ButtonResponsive2
				margin-left: 40px
			div.DivContent
				margin: 0
				padding: 100px 50px
				div.DivContentTitle
					flex-direction: row
					h1
						font-weight: 500
						margin: 0
						letter-spacing: 10px
						align-self: center
				div.DivContentDescription
					p.PCorporateDescription
						line-height: 55px
						font-size: 2.4em
						padding: 20px 40px
		div#divBodyDashboardAward
			height: 100%
			div.DivContentAwardsTitle
				h1
					text-align: left
			div.DivContentAwardsContent
				height: 420px
				margin: 50px
				margin-left: 0
				margin-right: 0
				flex-direction: row
				align-items: unset
				justify-content: unset
				hr
					top: 50%
					display: block
				div.DivContentCalenderDetail
					margin: 0
					&:nth-of-type(odd)
						align-self: flex-start
						margin-top: 10px
					&:nth-of-type(even)
						align-self: flex-end
		div#divBodyDashboardBRINSUpdate
			height: 100%
			div.DivContentCorporateSlider
				margin: 100px 50px
				div.DivContentCorporateTitle
					flex-direction: row
					h1
						margin: 0 !important
						text-align: left
						align-self: flex-start
					div.DivContentHeader
						div.DivContentTextDetail
							margin: 0
							p
								text-align: left
					input.ButtonResponsive
						margin-right: 0
				div.DivContentImageCorousel
					overflow: hidden
					margin-bottom: 20px
		div#divBodyDashboardPartners
			height: 100%
			input[type=button].ButtonResponsive2
				margin-left: 0
				margin-top: 30px
			div.DivContentPartners
				margin: 50px
				height: auto
				div.DivContent2Column
					display: grid !important
					grid-template-columns: 1fr 0.8fr
					margin: 20px 40px !important
					height: 60%
					div.DivContent
						h2
							text-align: left
						&:nth-of-type(2)
							display: block
							div.DivContentShapeDescription
								display: block
								img.ImageSpin
									display: block
									width: 200px
									height: 200px
								img#logosPartners
									display: block
									width: 125px
									right: 15px !important
			div#divContentBRIGroup
				margin: 50px
				h1
					text-align: left
					margin-top: 40px
				div.DivContentImageGallery
					section#sectionImageGallery
						display: grid
						grid-template-columns: repeat(3, 1fr)
						box-sizing: border-box
						width: 100%
						label.labelImagePrevious, label.labelImageNext
							display: flex
							img
								height: 30px
								width: auto
								display: block
								margin: auto
								border-radius: unset
								opacity: 0.7
						label.labelImageActive
							display: flex
							img
								width: auto
								height: 40px
								border-radius: unset
								opacity: 1
								margin: auto

	// LANDING - END


	// NAVIGATION - START

	nav.NavMenuOverlay ul.ListNavigation
		label
			font-size: 1.1em

	div#divBodyHomeMenu
		padding: 10px
		nav#navigationMainHome
			height: 70px
			margin: 0 !important
			+setBorderRadius(25px)
			div.DivNavigationHeader
				height: 70px
				img.ImageLogoBRINS
					margin: auto 20px
			div.DivNavigationList
				height: 70px
				margin-right: 20px
				ul
					margin-right: 0px
					height: 70px
					li.ListIconNavigation
						margin: 0

	// NAVIGATION - END

	div.DivContentDownload
		font-size: 90%

// SCREEN WIDTH 1100px - END


// SCREEN WIDTH 1080px HEIGHT 1920px (KIOSK) - START

@media screen and (width: 1080px), screen and (height: 1920px)
	div#BotikaWidget
		visibility: hidden
	div#divNotificationInstall
		visibility: hidden
	div#divBodyHomeMenu
		font-size: 85%
		line-height: 26px
		nav#navigationMainHome
			// visibility: hidden
			height: 120px
			margin: 10px !important
			+setBorderRadius(40px)
			div.DivNavigationHeader
				height: 120px
				img.ImageLogoBRINS
					margin: auto 30px
					height: 50px
			div.DivNavigationList
				height: 120px
				margin-right: 20px
				ul
					margin-right: 20px
					height: 120px
					li.ListIconNavigation
						img#imageIconHamburger
							width: 60px
							height: 60px
		nav#navigationAside
			div.DivMenuHeader
				margin: 50px
				img#imageLogoDigital
					height: 70px
				img.ImageIconMenu
					height: 100px
			ul.ListNavigation
				li.ListNavigationMenu, li.ListItemNavigation
					margin: 30px 0px
					padding: 35px 50px
					label
						font-size: 3em
					ul.ListSubNavigation
						margin-top: 30px
						margin-left: 50px
						li.ListItemSubNavigation
							padding: 30px 30px
							margin-bottom: 20px
						li.ListItemSubNavigation:last-of-type
							margin-bottom: 0px

	div#divMainDashboard
		div#divMainCustomerCareSubmissionClaim
			div.DivHeaderSubmissionClaim
				img
					height: 700px
				div.DivContentNewsHorizontal
					margin-top: -450px
					border-radius: 60px
					img
						width: 300px
						height: 300px
					div.DivContentVertical
						h1
							font-size: 4em
						p
							margin: 0 40px 40px 40px
							font-size: 2.5em
			div.DivBodySiteContainer
				div.DivContentForm
					form
						max-width: 900px !important
						div.DivContentNewsVertical
							padding: 50px !important
							border-radius: 60px
							label
								font-size: 3em !important
							fieldset
								div.DivFormHorizontalContainer
									input
										font-size: 2em !important
										height: 140px
										border-radius: 70px
								div.DivContentButtonDetail
									margin-top: 40px
									.ButtonForward
										span
											font-size: 1.8em !important
											top: 5px
										svg
											height: 30px
											width: 30px
											top: 13px
											right: -15px
									.ButtonForward::before
										width: 180px !important
										height: 70px !important
										margin-top: -10px
		div#divBodyFooterContainer
			div.DivFooterInfoPage
				div#divSectionHQContactUs
					grid-template-columns: 1fr
					div.DivFooterContactUs
						a
							div.DivFooterSVGContainer
								img
									width: 60px
									height: 60px
						a.AFooterSVGContainer
							img
								width: 500px
				div.DivFooterVerticalContainer
					label
						font-size: 3em
					p
						line-height: 80px
						font-size: 2.5em
				div.DivFooterPageHorizontalContainer
					div.DivFooterSectionPage
						div.DivFooterLink
							div.DivFooterContentContainer
								div.DivParagraphLink
									grid-template-columns: 1fr
							div.DivFooterSocialMedia
								div.DivFooterHorizontalContainer
									div.DivFooterSVGContainer
										width: 140px
										img
											width: 100px
											height: 100px
							div.DivFooterHorizontalContainer
								div.DivFooterSVGLargeContainer, div.DivFooterSVGMediumContainer
									img
										height: 150px
						div#divFooterSubscribeContainer
							div.DivFooterVerticalContainer
								div.DivFormSubscribeEmail
									input
										height: 100px
										border-radius: 100px
										font-size: 2.5em
									button.ButtonSlideDetail
										top: 10px
										width: 100px
										height: 100px
										border-radius: 50px
		div#divBodyAbout
			div.DivContentMain
				flex-direction: column
				div.DivContentDescription
					p
						padding: 40px 0
						font-size: 3em
						line-height: 80px
						text-align: center
				div.DivContentAbout
					h1
						font-size: 6em
						width: 100%
						flex-direction: row
						justify-content: center
					div.DivContentSearch
						padding: 30px
						width: 94%
						height: 80px
						border-radius: 100px
						img
							width: 50px
							height: 50px
						div.DivContentSearchDetail
							label
								font-size: 2em
							select
								height: 50px
								font-size: 2em
								option
									font-size: 0.5em
				div.DivContentImage
					figure
						align-items: center
						width: 95%
						height: 95%
						padding: 50px 0px
						img:nth-of-type(1)
							height: 800px
							width: 800px

	// PARTNERSHIP WORKSHOP - START

	div#divCustomerCareWorkshop
		div.DivContentSearch
			display: flex !important
			margin: 30px 30px
			padding: 10px
			width: 94%
			box-sizing: border-box
			height: 130px
			border-radius: 70px
			div.DivContentSearchDetail
				width: 87%
				margin: 5px 10px
				label
					font-size: 2em
					color: #999090
				select
					height: 50px
					font-size: 2em
					option
						font-size: 0.5em
			img
				width: 50px
				height: 50px
		div#divTableContainerPartnershipWorkshop
			display: none
	div#divCustomerCareWorkshop, div#divContainerPartnershipWorkshop
		padding: 0px 20px
	div#divContainerPartnershipWorkshop
		padding: 0 50px
		div.DivContent2Column
			h3
				font-size: 3.5em
			div.DivContentLocation
				display: none
			div.DivContentInfo
				width: 100%
				display: block !important
				margin: 0
				div.DivFormHorizontalContainer
					display: flex
					flex-direction: column
					margin: 20px 0 0 0
					padding: 50px
					box-sizing: border-box
					border-radius: 50px
					+boxShadow(0, 0, 20px, 0, rgba(0, 0, 0, 0.2))
					label
						font-size: 2.5em
						margin-top: 10px
						&:first-of-type
							margin-top: 0
					span
						font-size: 2.5em
				div.DivFooterContentContainer
					margin-top: 0px !important

	// PARTNERSHIP WORKSHOP - END

// SCREEN WIDTH 1080px HEIGHT 1920px - END


// SCREEN WIDTH 950px - START

@media screen and (max-width: 950px)
	div#divBodyDashboard, div#divBodyRelationshipManagerDetail
		div.DivContainerHorizontal, div.DivContainerVertical
			div.DivFormVerticalContainer, div.DivFormHorizontalContainer
				flex-direction: column
				align-content: center
				align-items: center

	form div.DivForm fieldset, div.DivForm
		div.DivFormVerticalContainer
			flex-direction: column
			div.DivForm2Column
				margin-left: 0px
				margin-right: 0px

	form.CalculateForm
		width: 100%
		div.DivForm
			fieldset
				div.DivFormHorizontalContainer
					div.DivForm2Column
						width: 100%
		input[type=button].ButtonRight
			width: 100%

	div.DivTableContainer
		ul.ListTableConversation
			li.LiConversationContainerRight, li.LiConversationContainerLeft
				div.DivConversationStatus
					flex-direction: column
					flex-shrink: 1
				div.DivConversationHeader
					flex-direction: column
					align-items: flex-end
					div.DivConversationProfile
						display: none
			table.TableListDetails
				tbody
					tr
						td
							display: table-cell
						td:nth-child(2), td:nth-child(3)
							width: auto
						td:nth-child(3)
							display: none

	// div#divBodyAbout
	// 	div.DivContentMain
	// 		height: auto
	// 		flex-direction: column
	// 		div.DivContentAbout
	// 			margin: 150px 30px 20px 30px
	// 			width: auto
	// 			text-align: center
	// 			div.DivContentTableSearch
	// 				width: 90%
	// 				margin: 20px auto
	// 			div.DivContentDescription
	// 				p
	// 					text-align: center
	// 			div.DivContentButtonAbout
	// 				height: 100px
	// 				div.DivContentButtonDetail
	// 					a.ButtonForward:first-child
	// 						left: 40px
	// 		div.DivContentImage
	// 			width: auto
	// 			margin: 20px 0px
	// 			figure
	// 				width: 90vw
	// 				height: 90vw
	// 				margin: 20px auto
	// 				img:nth-of-type(2)
	// 					left: 50px
	// 				img:nth-of-type(3)
	// 					right: 50px
	// 				img:nth-of-type(4)
	// 					right: 0px
	// 				img:nth-of-type(5)
	// 					position: absolute
	// 					left: 0px
	// 				img:nth-of-type(4),img:nth-of-type(5)
	// 					height: $IMAGE_HEADER_ABOUT_SVG_MEDIUM_HEIGHT
	// 					width: $IMAGE_HEADER_ABOUT_SVG_MEDIUM_WIDTH
	// 				img:nth-of-type(2), img:nth-of-type(3)
	// 					height: $IMAGE_HEADER_ABOUT_SVG_SMALL_HEIGHT
	// 					width: $IMAGE_HEADER_ABOUT_SVG_SMALL_WIDTH
	// div.DivSiteContainer
	// 	margin: 0px 50px !important

// SCREEN WIDTH 950px - END


// SCREEN WIDTH 800px - START

@media screen and (max-width: 800px)
	div.DivBodyGeneral
		scroll-snap-align: unset
		position: relative

	div#divMainDashboard
		select, option
			font-size: 1.1em
		div.DivModal
			height: 500px
			div.DivModalHeader
				height: 60px
				h2
					font-size: 1.3em
			div.DivModalFigureContainer
				figure
					width: auto
					height: auto
					iframe
						height: 400px
						margin: 0

	div#divBodyDashboard1
		div.DivContentDashboard
			div.DivContentSection
				margin-top: 100px
				margin-bottom: 20px
				margin-left: 20px
				margin-right: 20px
				div.DivContentTitle
					h3
						font-size: 2em

	div#divBodyDashboardAward
		div.DivContentAwardsTitle
			margin: 100px 0px 0px 0px
			height: 50px
		div.DivContentAwardsContent
			flex-direction: column
			align-items: center
			justify-content: space-between
			hr
				display: none
				width: 0px
			div.DivVerticalLine
				display: block
				border-left: 6px solid  green
				position: absolute
			div.DivContentCalenderDetail
				top: 0px
				margin-top: 150px
				width: -webkit-fill-available

	div#divBodyDashboardBRINSUpdate
		div.DivContentCorporateSlider
			margin-top: 20px
			margin-bottom: 20px
			margin-left: 20px
			margin-right: 20px
			display: flex
			flex-direction: column
			justify-content: space-between
			div.DivContentCorporateTitle
				display: flex
				flex-direction: column
				height: 100%
				align-items: center
				div.DivContentTextDetail
					width: 100%
					p
						text-align: center
			div.DivContentImageCorousel
				margin-top: 20px
				padding: 0px
				section#sectionImageCorousel
					width: 30vh
					height: 30vh
			div.DivContentDescription
				display: flex
				flex-direction: column
				padding: 0px
				h1
					text-align: center
					font-size: 1.2em
				p
					font-size: 1em
				div.DivContentBrinsUpdate
					p
						-webkit-line-clamp: 3

	div#divBodyDashboardAboutBRINS
		div.DivContent
			margin-top: 100px
			margin-bottom: 20px
			margin-left: 20px
			margin-right: 20px
			div.DivContentTitle
				display: flex
				flex-direction: column
				margin-bottom: 0px
				hr
					margin: 20px 0px 20px 0px
					height: 0px
					background: #FFFFFF
			div.DivContentDescription
				height: auto !important
				padding-bottom: 20px
			div.DivContentButtonDetail
				text-align: left
				margin-top: 20px
				margin-left: 0px
		h1
			font-size: $FONT_LABEL_H3
			font-weight: 600
			margin-right: 0px
			margin-left: 0px
			letter-spacing: 5px
			width: 100%
		hr
			width: 100%
		p
			font-size: 1.75em
			text-align: justify-all
			margin-left: 0px

	div#divBodyDashboardPartners
		div.DivContentPartners
			margin-left: 20px
			margin-right: 20px
			height: 100%
			div.DivContent2Column
				display: flex
				margin: 20px
				height: 100%
				align-items: center
				flex-direction: column
				div.DivContent
					margin: 0px
					h2
						text-align: center
					p
						text-align: left
					img
						display: none
			div.DivContent:nth-child(odd)
				width: 100%
			div.DivContent:nth-child(even)
				width: 100%
			div.DivContentShapeDescription
				display: none
			img#imageIconPartners
				display: none
		div.DivContent
			margin-left: 20px
			margin-right: 20px
			h1
				margin-top: 0px
			div.DivContentImageGallery
				div.DivButtonNavigate
					img
						width: 10px
						height: 10px
						padding: 15px
				section#sectionImageGallery
					label.labelImagePrevious, label.labelImageNext
						display: none
					label.labelImageActive
						img
							width: 200px

	// BRINS UPDATE AND ABOUT MENU - START

	div#divBodyAbout
		font-size: 90%
		margin-bottom: 0px
		div.DivContentMain
			display: flex
			height: auto
			width: auto
			flex-direction: row
			padding: 15% 50px 0px 50px
			div.DivContentAbout
				display: flex
				flex-direction: column
				margin: 0
				width: 100%
				height: 100%
				align-self: flex-start
				h1
					margin-top: 10px
				div.DivHeaderAbout, div.DivAboutHeaderTitle
					text-align: center
			div.DivContentDescription
				width: 100%
				margin-top: 0px
				margin-bottom: 0px
				p
					padding: 0
					font-size: 1.1em
					line-height: 30px
					text-align: left
			div.DivContentSearch
				padding: 10px
				width: 90%
				div.DivContentSearchDetailFilter
					input[type=text]
						width: auto
			div.DivContentButtonAbout
				div.DivContentButtonDetail
					a.ButtonForward
						left: 0px
			h1
				font-size: 2.5em
				margin-left: 0
				display: flex
				flex-direction: column
				justify-content: flex-start
			p
				font-size: 1.2em
				font-weight: 550
		div.DivContentImage
			display: flex
			position: relative
			width: 100%
			height: 100%
			flex-direction: column
			align-items: center
			figure
				margin: 0
				display: flex
				flex-direction: column
				padding-right: 0
				align-items: flex-end
				img:nth-of-type(1)
					position: inherit
					left: 0px
					top: 0px
					height: 300px
					width: 300px
					border-radius: 25px
				img:nth-of-type(2), img:nth-of-type(3), img:nth-of-type(4), img:nth-of-type(5)
					display: none
		div#divContentWhatBRINS
			display: flex
			flex-direction: column
			height: 100vh
			div.DivContentAbout
				display: flex
				margin: 20px
				height: 100%
				flex-direction: column
				p.DivContentSectionInactive
					display: none
				p.DivContentSectionActive
					display: block
				p
					height: 100%
					font-size: 15pt
					text-align: center
				h1
					font-size: 2.8em
		div#divBodyMissionAndVission
			height: 100vh
			div.DivContentColumn
				display: flex
				flex-direction: column
				margin: 20px
				background-color: red
				align-items: center
				div.DivContentImage
					img
						width: 35vh
						object-fit: contain
					img.ImageShapeCEO
						position: absolute
						z-index: -1
						width: 100%
						height: 100%
	div#divBodyHeadlines
		padding: 0px 50px
		div.DivBodyFloatMain
			margin: 0 !important
			flex-direction: column
			margin: 0px 50px
			section:nth-child(1), section:nth-child(2)
				width: 100% !important
			section:nth-child(1)
				div.DivSectionHeadlines
					margin-right: 0 !important
					div.DivContentNewsVertical
						div.DivContentNewsDetail
							height: auto
			section.sectionNewsFeed, section.sectionHeadlines
				div.DivContentTitleHorizontal
					div.DivContentButtonDetail
						display: none
					input[type=button].ButtonResponsive
						display: flex !important
						box-sizing: border-box
						border-radius: 25px
						border: none
						padding: 10px 20px
						font-family: "Poppins-SemiBold"
						color: white
						background-color: #064F89
						align-self: center
						height: 40px
				div.DivSectionNewsFeed, div#divTestimonyDetail, div.DivContentCommunity
					display: grid !important
					grid-template-columns: repeat(2, 1fr)
					grid-column-gap: 30px
					div.DivContentNewsHorizontal
						grid-template-columns: 100px 1fr
						img
							width: 100px
			section.sectionHeadlines
				div#divTestimonyDetail
					div.DivContent
						width: 100%
						div.DivContentTestimony
							margin-right: 0
							height: auto
	div#divFinancialLiteracy
		font-size: 0.7em
		margin: 50px 0px !important
		padding: 0px 50px
		height: auto !important
		div.DivFinancialLiteracy
			padding: 25px 30px !important
			height: 250px
			box-sizing: border-box
			div.DivFinancialParagraphButton
				height: 150px
				display: flex !important
				flex-direction: column
				justify-content: flex-start
				align-items: flex-start
				margin-left: 0 !important
				p
					font-size: 1.1em
				input[type=button]
					-webkit-appearance: none
					box-sizing: border-box
					border-radius: 25px
					border: none
					padding: 10px 30px
					font-family: 'Poppins-SemiBold'
					color: white
					background-color: #F38F1E
			div.DivContentButtonDetail
				display: none
		div.DivFinancialLiteracyImage
			img
				height: 250px !important
	div#divBodyTabAbout
		div.DivContentMain
			div.DivContentTabAbout
				button
					width: 250px
					font-size: 0.9em !important
		div.DivContentDownload
			margin-top: 15px
			div.DivContentDownloadMedium
				+setBorderRadius(20px)
				img
					width: 120px
				div.DivContentDownloadDescription
					label
						font-size: 1.1em
						line-height: 24px

	// BRINS UPDATE AND ABOUT MENU - START


	// NEWSFEEDS BRINS UPDATE - START

	div#divMainBRINSUpdateArticle
		img.ImageHeaderArticle
			width: 100%
		div.DivBRINSUpdateArticleDetail
			margin: 30px 35px
			div.DivContentHorizontal
				flex-direction: column

	div#divBodyContentMilestone
		padding: 0px 30px 25px 30px
		div.DivContent2Column
			display: grid
			grid-template-columns: repeat(2, 1fr)
			grid-gap: 10px
			font-size: 0.9em
			div.DivContentAwards
				width: 100%
				margin: 0
				img
					width: 120px
					height: 100%
				div.DivContentAwardsDetail
					width: 100%
					height: 185px
					h5
						display: -webkit-box
						-webkit-line-clamp: 3
						-webkit-box-orient: vertical
						overflow-y: hidden

	// NEWSFEEDS BRINS UPDATE - START


	// TESTIMONY BRINS UPDATE - START

	div#divTestimonyContainer
		padding: 0px 25px
		margin: 0 !important


	// TESTIMONY BRINS UPDATE - END


	// FINANCIAL LITERACY BRINS UPDATE - START

	div#divFinancialLiteracyContainer
		margin: 0 !important
		padding: 0px 25px
		font-size: 90%
		section.Section2Column
			div.DivSectionContent
				div.DivContentNewsVertical
					img, div.DivContentNewsDetail
						height: 210px !important
					div.DivContentNewsDetail
						div.DivContentNewsParagraph
							display: flex
							flex-direction: column
							justify-content: space-between
							div.DivParagraphInnerHTML
								p
									display: -webkit-box
									-webkit-line-clamp: 3
									-webkit-box-orient: vertical
									overflow-y: hidden
		section.Section4Column
			display: grid !important
			grid-template-columns: repeat(auto-fill,minmax(230px,1fr))
			grid-auto-flow: column
			grid-auto-columns: minmax(230px,1fr)
			grid-gap: 20px
			overflow-x: auto
			div.DivSectionContent
				width: 100% !important

	// FINANCIAL LITERACY BRINS UPDATE - END


	// COMMUNITY BRINS UPDATE - START

	div#divCommunityContainer
		margin: 0 !important
		padding: 0px 50px !important
		section.Section2Column
			div.DivContent
				figure
					flex-direction: column
					text-align: center
					margin-bottom: 60px
					div.DivImageBackground
						img
							height: 200px
					figcaption
						padding: 0
						h3
							margin-top: 40px
						p
							text-align: center
							font-size: 1.1em
							line-height: 30px

	// COMMUNITY BRINS UPDATE - END


	// FOOTER - START

	div#divMainLanding, div#divMainDashboard
		div#divBodyFooterContainer
			height: 100%
			scroll-snap-align: unset
			div.DivFooterInfoPage
				div.DivFooterVerticalContainer
					width: 100%
					display: flex
					flex-direction: column
					align-items: center
					box-sizing: border-box
					label
						display: block
						text-align: center
						font-family: 'Poppins-Regular' !important
						font-size: 1.2em
					p
						text-align: center
						font-size: 0.8em
					div.DivFooterContentContainer
						div.DivFooterSVGContainer
							display: none
					div.DivFooterHorizontalContainer
						display: flex
						justify-content: center
						&:first-of-type
							margin-top: -5px
							margin-bottom: 0px
						div.DivFooterSVGLargeContainer, div.DivFooterSVGMediumContainer
							img
								margin-top: 0px !important
					div.DivFooterSocialMedia
						margin-top: 0px
						margin-bottom: 40px
						div.DivFooterHorizontalContainer
							justify-content: flex-start
							margin: auto
							div.DivFooterSVGContainer
								width: 60px
								img
									display: block
									width: 30px
									height: 30px
									margin: 10px 20px
				div#divSectionHQContactUs
					display: grid
					grid-template-columns: repeat(2, 1fr)
					text-align: center
					div.DivFooterHQLocation
						div.DivFooterContentContainer
							margin: 0 !important
							margin-bottom: 40px !important
				div.DivFooterPageHorizontalContainer
					width: 100%
					div.DivFooterSectionPage
						display: flex
						flex-direction: column
						div.DivFooterLink
							div.DivFooterContentContainer
								width: 100%
								div.DivParagraphLink
									display: grid
									grid-template-columns: repeat(2, 1fr)
									width: 100%
						div#divFooterSubscribeContainer
							width: 100%
							margin-bottom: 10px
							margin-top: 10px
							box-sizing: border-box
							div.DivFooterVerticalContainer
								width: auto
								div.DivFormSubscribeEmail
									width: 100%
									font-size: 90%
			div.DivFooterCopyright
				display: none
			div.DivFooterCopyrightResponsive
				display: flex
			div.DivFormSubscribeEmail
				margin-top: 15px !important
				margin-bottom: 15px !important

	// FOOTER - END


	// START FAQ

	div#divMainTermsAndCondition
		div.DivHeaderPolicy
			height: auto
			img#imgHeaderShape
				display: none
			img#imgHeaderResponsive
				display: block
			h1
				font-size: 2em
		div.DivSiteContainer
			margin: 10px 40px
			section
				h1, h3
					margin-top: 0
					margin-bottom: 20px
					font-size: 1.5em

	// END FAQ


	// START - ABOUT US

	div#divMainDashboard
		div#divBodyAbout
			div.DivContentMain
				div.DivContentAbout
					div.DivContentButtonAbout
						height: 90px
						width: 100%
					div.DivContentButtonDetail
						a.ButtonPlay
							padding: 10px 5px
							svg
								width: 10px !important
								height: 10px !important
								margin-left: 12px
								margin-bottom: 0px
							span
								margin-left: 20px
								font-size: 1em
						a.ButtonPlay:before
							width: 35px
							height: 35px
							border: 3px solid #064F89
						input[type=button].ButtonResponsive
							position: absolute
							display: flex !important
							box-sizing: border-box
							border-radius: 25px
							border: none
							padding: 10px 20px
							font-family: "Poppins-SemiBold"
							color: white
							background-color: #064F89
							align-self: center
							height: 40px
						.ButtonForward
							display: none
			div#divContentWhatBRINS
				height: 340px
				padding: 0 20px
				div.DivContentAbout
					p
						font-size: 1.1em
						line-height: 30px
						text-align: justify
					h1
						font-size: 2em
						font-family: "Poppins-Bold"
						font-weight: bold
						text-align: left
		div#divBodyMissionAndVission
			div.DivContent2Column
				margin: 30px
				div.DivContentImage
					img.ImageShapeCEO
						display: none
					img.ImageCEO
						position: relative
						width: 300px
						bottom: 0
						margin-bottom: 40px
					label#labelImageCEOAbout
						position: absolute
						bottom: 8px
				div.DivContent
					p
						font-size: 1.2em
						width: 280px !important
					h1
						font-size: 1.5em
			div.DivContentDescription
				margin: 30px
				height: 270px
				div.DivContentVisionDescriptionDetail, div.DivContentMissionDescriptionDetail
					padding: 5px
					box-sizing: border-box
					img
						width: 55px
						height: 55px
						margin: 0
					h1
						margin: auto 0px
						font-size: 1.8em
				div.DivContentVisionDescriptionParagraph, div.DivContentMissionDescriptionParagraph
					margin-left: 40px
					margin-right: 40px
				div.DivContentVisionDescriptionInactive, div.DivContentMissionDescriptionInactive
					h1
						margin: auto !important
				div.DivContentMissionDescriptionActive, div.DivContentVisionDescriptionActive
					div.DivContentMissionDescriptionDetail, div.DivContentVisionDescriptionDetail
						margin-top: 10px
					div.DivContentMissionDescriptionParagraph, div.DivContentVisionDescriptionParagraph
						margin-left: 20px
						margin-right: 30px
						p
							font-size: 1em
							line-height: 24px
						ol.OrderedNumber
							margin-left: 20px
							margin-top: 0
							margin-bottom: 0
							p, li::marker
								font-size: 1em
								line-height: 25px
								margin-top: 0
								margin-bottom: 0
			div#divContentVisionMissionBRINS
				height: 340px
				font-size: 90%
				div.DivContentVisionDescription
					div.DivContentVisionDescriptionDetail
						padding: 10px
					div.DivContentVisionDescriptionParagraph
						margin: 0 30px
				div.DivContentMissionDescription
					div.DivContentMissionDescriptionDetail
						padding: 10px
		div#divBodyInformation
			flex-direction: column
			div.DivContentMain, div.DivContentDescription
				width: 100%
				padding: 20px
				box-sizing: border-box
			div.DivContentMain
				padding-bottom: 0
				img.ImageIconCircuit
					display: none
				div.DivContentTextMain
					top: 0
					margin: auto 20px
					img.ImageShapeContentText
						display: none
					h1
						font-size: 2em
						margin: 20px auto

					p
						font-size: 15.84px !important
						line-height: 30px
			div.DivContentDescription
				padding-top: 0
				div.DivContentBoxDetail
					height: 315px
					margin: 15px auto
					+setBorderRadius(25px)
					img
						width: 120px
						height: 120px
					div.DivContentBoxDescription
						margin: 0 20px
						h4
							font-size: 1.3em
						div.DivContentBoxText
							p
								font-size: 0.9em
								line-height: 21px
								text-align: left
					div.DivContentButtonDetail
						margin-left: 20px
						font-size: 0.8em
						height: 0
						input[type=button].ButtonResponsive
							position: absolute
							display: flex !important
							box-sizing: border-box
							border-radius: 25px
							border: none
							padding: 10px 20px
							font-family: "Poppins-SemiBold"
							color: white
							background-color: #064F89
							align-self: center
							height: 40px !important
						div.ButtonForward
							display: none
				div.DivContentBoxDetail:nth-child(odd)
					margin-left: 0
					margin-right: 15px
				div.DivContentBoxDetail:nth-child(even)
					margin-left: 15px
					margin-right: 0
		div.DivTabContainer
			margin: auto 20px
			div.DivContentTabAbout
				width: 100%
				display: flex
				button
					flex-shrink: 1
					flex-grow: 1
					flex-basis: 200px
					width: auto
					font-size: 1em
					padding: 0
		div#divBodyStructureOrganization
			div.DivContentMain, div.DivContentDescription
				padding: 20px 30px
			div.DivContentMain
				h1
					margin: 0
					font-size: 2em
			div.DivContentDescription
				padding: 20px 30px
				h3
					font-size: 1.2em
					margin-bottom: 0
				h4
					font-size: 1.1em
					margin-bottom: 0
				p
					font-size: 1em
					line-height: 26px
				img
					width: 250px
					margin-top: auto
					margin-bottom: auto
			div.DivContentMainDivision, div.DivContentDescriptionDivision
				margin: 20px 40px
				img
					width: 100%
					margin: 30px 0
				h1
					font-size: 2em
			div.DivContentDescriptionDivision
				justify-content: space-between
				display: grid
				grid-template-columns: 1fr 1fr
	div#divBodyContentMilestone
		h1
			font-size: 1.8em
		div.DivContentHorizontal
			div.DivContentMilestoneDetail
				width: 440px
				height: 580px
				img
					width: 400px
					height: 290px
					margin: auto
				div.DivContentPoint
					padding: 10px
					p.ParagraphCenter
						width: 100%
						font-size: 1.1em
						line-height: 28px
			div.DivContentMilestoneDetail:hover
				img
					width: 440px
					height: 300px
					margin: auto
		div.DivContent2Column
			div.DivContentAwards
				div#divContentAwardsDetail
					height: 165px
					label
						margin: 10px
					h5
						margin: 0 10px
					a.ButtonForward
						position: relative
						bottom: 0px
						right: 0px
						margin-left: auto
						margin-right: 10px
						margin-top: auto
						margin-bottom: 10px
						span
							font-size: 1em

	// START - PARTNER

	div#divBodyPartnerAndClient
		margin: 40px
		h1
			font-size: 2em
			margin: 10px
		div.DivContentDescription
			div.DivContentBoxDetail
				div.DivContentShapeDescription
					img:first-of-type
						height: 130px
						width: 130px
					img:last-of-type
						width: 90px

	// END - PARTNER

	// END - ABOUT US


	// CUSTOMER CARE - START

	div#divContentCheckPolicy, div#divContentPartnerWorkshop, div.DivBodySiteContainer
		padding: 25px 50px
	div#divContentCheckPolicy, div#divContentPartnerWorkshop
		div.DivContent2Column
			flex-direction: column
			margin: 0
			div.DivContent
				width: 100%
				box-sizing: border-box
			div.DivContentForm
				margin: 0
				form
					margin-bottom: 0
					div.DivContentNewsVertical
						margin: 0
			div.DivContentClaimCenter
				width: 100%
				box-sizing: border-box
	div#divContentCheckPolicy
		div.DivContent2Column
			div.DivContent
				&:nth-child(2)
					padding: 30px 50px
		input[type=button].ButtonResponsive
			font-size: 1em !important
	div#divContentPartnerWorkshop
		div.DivContent2Column
			div#divContentBoxDetailClaim
				padding: 0
				img
					width: 80px
					margin-left: 20px
				div.DivContentBoxDescription
					height: 100px
				div.DivContentButtonDetail
					margin: 25px auto
			div.DivContent2Column
				display: grid
				grid-template-columns: repeat(2, 1fr)
				padding: 0
				grid-gap: 30px
				margin: 10px 0px
			&:nth-child(2)
				flex-direction: column-reverse
				div.DivContentLocationMaps, div.DivContentPartnershipWorkshop
					width: 100%
				div.DivContentPartnershipWorkshop
					margin: 0
					div.DivContentDescription
						margin: 40px 0 20px 0
						h2, p
							text-align: left
						div.DivContentButtonDetail
							div.ButtonForward
								margin-left: 0
			div.DivContent3Column
				display: grid
				grid-template-columns: 1fr !important
				margin: 10px 0px 10px 55px
	div#divContentTicketAndCallCenter
		div.DivContent2Column
			grid-template-columns: 1fr !important
			div.DivContent
				div.DivContentForm
					form
						padding: 0px 50px
						box-sizing: border-box
				div.DivContentDescription
					display: grid
					grid-template-columns: repeat(3, 1fr)
					grid-gap: 30px
					margin: 20px !important
					div.DivContentDetail
						margin: 0 !important
						img
							height: 80px !important
	div#divContentCheckPolicy, div#divContentPartnerWorkshop, div#divContentTicketAndCallCenter
		input[type=button].ButtonResponsive
			display: flex !important
			box-sizing: border-box
			+setBorderRadius(50px)
			border: none
			padding: 10px 30px
			font-family: "Poppins-SemiBold"
			color: white
			background: #064F89
			align-self: center
			// width: auto !important
			height: 55px
			margin-left: auto
			margin-right: auto
			margin-bottom: 20px
			font-size: 1.2em
			-webkit-appearance: none
		div.DivContentButtonDetail
			display: none

	// CUSTOMER CARE - END


	// PARTNERSHIP WORKSHOP - START

	div#divContainerPartnershipWorkshop
		hr.HeadingBorder
			display: none
		div.DivContent2Column
			div.DivContentLocation
				width: 100%
				div.DivContent
					box-sizing: border-box
					margin-top: 20px
					width: 100%
					display: grid
					grid-template-columns: repeat(3, 1fr)
					height: 100%
					align-items: flex-start
					div.DivContentLocationDetail
						p
							font-size: 15.84px !important
			div.DivContentLocationMaps
				margin: 20px 0 0 0
				width: 100%
				div.DivFormNoBorder
					margin: 20px 0 0 0
					form
						div.my-google-map
							margin-right: 0
	div#divTableContainerPartnershipWorkshop
		table.TableBox
			thead, tbody
				tr
					td, th
						padding: 10px
						font-size: 0.8em
						div.DivContentButtonDetail
							a.ButtonForwardTable::before
								width: 50px
								height: 50px
							a.ButtonForwardTable
								padding: 15px 10px
					td:nth-child(1)
						width: 5%
					td:nth-child(2)
						width: 15%
					td:nth-child(3)
						width: 35%
					td:nth-child(4)
						width: 10%
					td:nth-child(5)
						width: 15%
					td:nth-child(6)
						width: 20%

	// PARTNERSHIP WORKSHOP - END

	// COMPANY PROFILE - START

	div#divMainDashboard
		div#divBodyContentOrdered
			margin: 40px
			h1
				margin-top: 0
				margin-bottom: 0
				font-size: 2em
			p
				font-size: 1em
				line-height: 26px
			div.DivContent2Column
				margin: auto
			div.DivContentMain
				figure
					iframe
						height: 400px

	// COMPANY PROFILE - END


	// CORPOTATE VALUE - START

	div#divMainDashboard
		div#divBodyContentOrdered
			div#divContent2ColumnBrillianWays
				flex-direction: column
				div.DivContent
					div.DivContentOrderedListDescription
						margin-right: 0
						margin-bottom: 10px
						h1, p
							text-align: left
						p
							margin: 5px 0
						h1
							font-size: 1.5em
			div#divContent2ColumnCoreValue
				flex-direction: column-reverse
				div.DivBodyContentOrderedList
					div.DivContentOrderedListImage
						margin: 15px 0px
						margin-right: 20px
					div.DivContentOrderedListDescription, div.DivContentOrderedListImage
						margin-top: auto
						margin-bottom: auto
				h2.headingFirstLetterBig, h2.headingFirstLetterBig::first-letter
					font-size: 1.5em
					font-family: 'Poppins-SemiBold'
			div#divContent2ColumnCoreValue, div#divContent2ColumnBrillianWays
				div.DivContent, div.DivContentImage
					width: 100%
				div.DivContentImage
					margin-top: 20px
					margin-bottom: 30px
					img
						width: 100%

	// CORPORATE VALUE - END


	// CONTACT AND LOCATION - START

	// div#divMainDashboard
	// 	div#divContactLocationContainer
	// 		table
	// 			thead, tbody
	// 				tr
	// 					td, th
	// 						padding: 10px
	// 						font-size: 0.8em
	// 						div.DivContentButtonDetail
	// 							a.ButtonForwardTable::before
	// 								width: 50px
	// 								height: 50px
	// 							a.ButtonForwardTable
	// 								padding: 15px 10px
	// 					td:nth-child(1)
	// 						width: 5%
	// 					td:nth-child(2)
	// 						width: 15%
	// 					td:nth-child(3)
	// 						width: 35%
	// 					td:nth-child(4)
	// 						width: 10%
	// 					td:nth-child(5)
	// 						width: 15%
	// 					td:nth-child(6)
	// 						width: 20%
	// 		div.DivContent2Column
	// 			div.DivContentLocation, div.DivContentLocationMaps
	// 				width: 100%
	// 				div.DivContent
	// 					width: 100%
	// 					display: flex
	// 					margin-top: 20px
	// 					margin-bottom: 20px
	// 					div.DivContentLocationDetail
	// 						flex-grow: 1
	// 						flex-shrink: 1
	// 						flex-basis: 150px
	// 						width: auto
	// 						margin: 10px
	// 						h4
	// 							margin: 10px auto
	// 			div.DivContentLocationMaps
	// 				margin: 10px 0
	// 		div.DivContentLocationMaps
	// 			div.DivFormNoBorder
	// 				margin: 20px 0
	// 		hr
	// 			margin-bottom: 50px
	// 			margin-top: 0

	// CONTACT AND LOCATION - END


	// SOCIAL VALUE - START

	div#divBodySocialValueCEO
		margin: 0 !important
		font-size: 90%
		div.DivContent2Column
			display: grid
			grid-template-columns: 0.7fr 1fr
			margin: 0 !important
			grid-column-gap: 20px
			div.DivContentImage
				width: 100%
				margin: 0 !important
				img.ImageShapeCEO
					left: unset !important
					top: 21%
					bottom: 0
					margin: auto 0 auto 0 !important
			div.DivContent
				width: 100% !important
				div.DivContentMessageCEO
					margin-top: 25px !important
					margin-right: 0
					text-align: center !important
					h2
						font-size: 1.5em !important
					p
						text-align: center
						line-height: 30px
						font-size: 1.1em
	div#divBodySocialValueInformation
		padding: 0px 50px !important
		margin: 0 !important
		flex-direction: column
		div#divContainMainHeader
			display: grid !important
			grid-template-columns: repeat(4, 1fr)
			grid-column-gap: 15px
			font-size: 90%
			width: 100% !important
			div.DivContentBoxDetail
				width: 100% !important
				height: 170px !important
				margin: 0 !important
				padding: 10px
				box-sizing: border-box
				div.DivContentDetail
					img
						width: 100px !important
					div.DivContentBoxDescription
						width: 100% !important
						font-size: 80%
						height: 50px
		div.DivContentDescription
			width: 100% !important
			margin-top: 40px
			div#divBodyTabAbout
				div.DivContentMain
					h3
						text-align: center
					div.DivContentTabAbout
						box-sizing: border-box
	div#divBodySocialValueInformation, div#divBodySocialValueCSR
		div.DivContentDescription
			h1
				font-size: 2em !important
			div.DivContentMain
				p
					font-size: 1.1em
					line-height: 24px
				ol.OrderedNumber
					margin-left: 30px
	div#divBodySocialValueCSR
		div.DivContentMain
			display: grid !important
			grid-template-columns: repeat(2, 1fr)
			grid-column-gap: 20px
			div.DivContentBoxDetail
				width: 100% !important
				height: 100% !important
				margin: 0 !important
				div.DivContentBoxDescription
					height: 80px !important
	div#divBodyDashboardBRINSUpdate
		div#divSocialValueBRINSUpdate
			div.DivContentCorporateTitle
				h1
					font-size: 2em
			div.DivContentImageCorousel
				overflow: hidden
				padding: 20px 0px 40px
				margin-bottom: 10px
				section#sectionImageCorousel
					width: 29vh
					height: 29vh
			div.DivContentDescription
				p
					font-size: 1.1em !important

	// SOCIAL VALUE - END


	// LANDING - START

	div#divMainLanding
		overflow-x: hidden
		input[type=button].ButtonResponsive, input[type=button].ButtonResponsive2
			display: flex !important
			box-sizing: border-box
			+setBorderRadius(30px)
			border: none
			padding: 10px 20px
			font-family: "Poppins-SemiBold"
			color: white
			align-self: center
			height: 40px
			margin-left: auto
			margin-right: auto
		input[type=button].ButtonResponsive
			background-color: #064F89
		input[type=button].ButtonResponsive2
			background-color: #F7931E
		div.DivContentButtonDetail
			display: none
			-webkit-appearance: none
		div#divBodyDashboard1
			input[type=button].ButtonResponsive
				height: 25%
			div.DivPage
				flex-grow: 3
				margin-bottom: 70px
		div#divBodyDashboardBussinesPillars
			padding: 100px 30px
			box-sizing: border-box
			div.DivContentLayoutContent
				padding: 0
				div.DivContentCorporate
					margin: 0 !important
					div.DivContentCorporatePage
						div.DivContentCorporatePageImage
							margin-top: 50px
							div.DivClipWrap
								img
									width: 100%
									object-fit: contain
							h1
								font-size: 7em
					div.DivContentCorporateDescription
						p
							height: 90px
							font-size: 13pt !important
			div.DivContentLayoutSide
				display: inline-flex
				justify-content: center
				flex-direction: column
				span
					margin: 3px 0
				span.active
					width: 12px
					height: 45px
		div#divBodyDashboardAboutBRINS
			input[type=button].ButtonResponsive2
				margin-left: 40px
				margin-bottom: 50px
			div.DivContent
				margin: 0
				padding: 10% 50px 0px 50px
				div.DivContentTitle
					flex-direction: row
					h1
						font-weight: 500
						margin: 0
						letter-spacing: 10px
						align-self: center
				div.DivContentDescription
					p.PCorporateDescription
						line-height: 55px
						font-size: 2em
						padding: 20px 40px !important
		div#divBodyDashboardAward
			div.DivContentAwardsTitle
				h1
					text-align: left
			div.DivContentAwardsContent
				height: 420px
				margin: 50px
				margin-left: 0
				margin-right: 0
				flex-direction: row
				align-items: unset
				justify-content: unset
				hr
					top: 50%
					display: block
				div.DivContentCalenderDetail
					margin: 0
					&:nth-of-type(odd)
						align-self: flex-start
						margin-top: 10px
					&:nth-of-type(even)
						align-self: flex-end
		div#divBodyDashboardBRINSUpdate
			div.DivContentCorporateSlider
				margin: 50px
				div.DivContentCorporateTitle
					flex-direction: row
					h1
						margin: 0 !important
						text-align: left
						align-self: flex-start
					div.DivContentHeader
						div.DivContentTextDetail
							margin: 0
							p
								text-align: left
				div.DivContentImageCorousel
					section#sectionImageCorousel
						width: 21vh
						height: 21vh
		div#divBodyDashboardPartners
			input[type=button].ButtonResponsive2
				margin-left: 0
				margin-top: 30px
			div.DivContentPartners
				margin: 50px
				div.DivContent2Column
					display: grid !important
					grid-template-columns: 1fr 0.8fr
					margin: 20px 40px !important
					height: 60%
					div.DivContent
						h2
							text-align: left
						&:nth-of-type(2)
							display: block
							div.DivContentShapeDescription
								display: block
								img.ImageSpin
									display: block
									width: 200px
									height: 200px
								img#logosPartners
									display: block
									width: 125px
									right: 15px !important
			div#divContentBRIGroup
				margin: 50px
				h1
					text-align: left
					margin-top: 40px
				div.DivContentImageGallery
					section#sectionImageGallery
						display: grid
						grid-template-columns: repeat(3, 1fr)
						box-sizing: border-box
						width: 100%
						label.labelImagePrevious, label.labelImageNext
							display: flex
							img
								height: 30px
								width: auto
								display: block
								margin: auto
								border-radius: unset
								opacity: 0.7
						label.labelImageActive
							display: flex
							img
								width: auto
								height: 40px
								border-radius: unset
								opacity: 1
								margin: auto

	// LANDING - END


	// NAVIGATION - START

	nav.NavMenuOverlay ul.ListNavigation
		label
			font-size: 1.1em

	div#divBodyHomeMenu
		padding: 10px
		nav#navigationMainHome
			height: 70px
			margin: 0 !important
			+setBorderRadius(25px)
			div.DivNavigationHeader
				height: 70px
				img.ImageLogoBRINS
					margin: auto 20px
			div.DivNavigationList
				height: 70px
				margin-right: 20px
				ul
					margin-right: 0px
					height: 70px
					li.ListIconNavigation
						margin: 0

	// NAVIGATION - END


	div.DivContentDownload
		font-size: 90%

// SCREEN WIDTH 800px - END


// SCREEN WIDTH 720px - START

@media screen and (max-width: 720px)
	div.DivBodyContainer
		div#divBodyHomeMenu
			nav#navigationMainHome
				margin: 10px

	ul.ListTableConversation
		li.LiConversationContainerRight, li.LiConversationContainerLeft
			div.DivConversationGeneral
				div.DivConversationItem
					flex-direction: column
					align-items: flex-start
					div.DivConversationHeader
						margin-top: $SPACE_LITTLE
						div.DivConversationSubHeader
							span
								text-align: left !important
							span.SpanConversationTitle
								max-width: initial !important
	div.DivSiteContainer
		margin: 0px 20px !important

	// START BRINS UPDATE - NEWSFEEDS

	div#divMainBRINSUpdateArticle
		img.ImageHeaderArticle
			width: 100%
		div.DivBRINSUpdateArticleDetail
			margin: 30px 35px
			h2
				font-size: 1.1em
			label, p
				font-size: 0.9em
				line-height: 26px

	// END BRINS UPDATE - NEWSFEEDS

	// START FAQ

	div#divMainTermsAndCondition
		font-size: 90%
		div.DivHeaderPolicy
			height: 100%
			img#imgHeaderShape
				display: none
			img#imgHeaderResponsive
				display: block
			h1
				margin-top: 35px
				font-size: 1.5em
		div.DivSiteContainer
			margin: 10px 40px
			section
				h1, h3
					margin-top: 0
					margin-bottom: 20px
				h1
					font-size: 1.8em
				h3
					font-size: 1em
				p
					font-size: 1em
					line-height: 22px
				div.DivButton2Column
					button
						label
							font-size: 1em
				article
					div.DivDetailFAQ
						p
							font-size: 1em !important
							line-height: 22px
						div.DivButton2Column
							button
								label
									font-size: 1em !important
						div.DivHorizontalContainer
							label
								font-size: 1em !important

	// END FAQ


	// BRINS UPDATE MENU - START

	div#divBodyAbout
		div.DivContentMain
			height: 100%
			flex-direction: column
			padding: 25% 20px 0px 20px
			div.DivContentAbout
				align-items: center
				justify-content: center
				h1
					margin: 0
				div.DivHeaderContent
					display: flex
					h1
						margin: 0
						margin-top: 10px
						&:nth-child(2)
							margin-left: 10px
				div.DivContentDescription
					margin-top: 20px
					p
						padding: 0
						font-size: 1em
						line-height: 26px
						text-align: center !important
					div.DivContentSearchDetailFilter
						input
							width: 100%
				div.DivContentSearch
					div.DivContentSearchDetailFilter
						input
							width: 100%
							font-size: 1em
				div.DivContentSearch#divSelectProvinceWeb
					display: none
				div.DivContentButtonAbout
					div.DivContentButtonDetail
						input[type=button].ButtonResponsive
							-webkit-appearance: none
							width: 150px
							margin-left: 25px !important
							font-weight: 600
							text-align: center
		div.DivContentImage
			height: auto
			figure
				width: 80%
				height: 80%
				margin-top: 40px
				margin-bottom: 10px
				padding: 0
				display: flex
				align-items: center
				img:nth-of-type(2), img:nth-of-type(3), img:nth-of-type(4), img:nth-of-type(5)
					display: none
	div#divBodyHeadlines, div#divFinancialLiteracy
		padding: 0px 20px
	div#divBodyHeadlines
		h2
			font-size: 1.8em
		div.DivBodyFloatMain
			section.sectionHeadlines, section.sectionNewsFeed
				div.DivContentTitleHorizontal
					height: 50px !important
				div.DivSectionHeadlines
					div.DivContentNewsVertical
						img
							height: 200px
							background-position-y: top
						div.DivContentNewsDetail
							font-size: 0.9em
							div.DivParagraphContainer
								p
									display: -webkit-box
									overflow-y: hidden
									-webkit-line-clamp: 4
									-webkit-box-orient: vertical
									&:not(:first-child)
										display: none
				div.DivContentTitleHorizontal
					input[type=button].ButtonResponsive
						display: flex !important
						box-sizing: border-box
						border-radius: 25px
						border: none
						padding: 0px 20px
						font-family: "Poppins-SemiBold"
						color: white
						background-color: #064F89
						align-self: center
						height: 30px
				div.DivSectionNewsFeed
					label
						font-size: 0.8em
				div.DivSectionNewsFeed, div#divTestimonyDetail, div.DivContentCommunity
					grid-template-columns: 1fr
					grid-row-gap: 20px
					div.DivContentTestimony
						p
							font-size: 0.9em
							overflow-y: hidden
							display: -webkit-box
							-webkit-line-clamp: 4
							-webkit-box-orient: vertical
							&:not(:first-child)
								display: none
	div#divFinancialLiteracy
		div.DivContent
			&:nth-child(1)
				width: 100% !important
				border-top-right-radius: 25px
				border-bottom-right-radius: 25px
			&:nth-child(2)
				display: none
		div.DivFinancialLiteracy
			div.DivFinancialParagraphButton
				justify-content: space-between
	div#divBodyTabAbout
		margin: 20px 40px
		h1
			font-size: 1.4em
		div.DivContentMain
			div.DivContentTabAbout
				display: flex
				width: 100%
		div.DivContentDownload
			margin-top: 10px
			div.DivContentDownloadMedium
				+setBorderRadius(15px)
				margin-top: 10px
				margin-bottom: 10px
				img
					width: 100px
				div.DivContentDownloadDescription
					label
						font-size: 1em
						line-height: 22px

	// BRINS UPDATE MENU - END


	// NEWSFEEDS BRINS UPDATE - START

	div#divBodyContentMilestone
		div.DivContent2Column
			grid-template-columns: 1fr
			div.DivContentAwards
				div.DivContentAwardsDetail
					a.ButtonForward
						right: 10px
						span
							font-size: 0.9em


	// NEWSFEEDS BRINS UPDATE - END

	// COMMUNITY BRINS UPDATE - START

	div#divCommunityContainer
		section.Section2Column
			div.DivContent
				figure
					figcaption
						p
							font-size: 0.9em
							line-height: 26px

	// COMMUNITY BRINS UPDATE - END

	// TESTIMONY BRINS UPDATE - START

	div#divTestimonyContainer
		section.Section3Column
			div.DivContentTestimony
				div.DivContentTestimonyDetail
					p, label
						font-size: 0.9em
						line-height: 26px

	// TESTIMONY BRINS UPDATE - END

	// FINANCIAL LITERACY BRINS UPDATE - START

	div#divFinancialLiteracyContainer
		section.Section2Column
			div.DivSectionContent
				h3
					font-size: 1.1em
				p
					font-size: 0.9em
					line-height: 26px
					text-align: left
		section.Section4Column
			div.DivSectionContent
				p
					font-size: 0.9em
					line-height: 26px
					text-align: left

	// FINANCIAL LITERACY BRINS UPDATE - END

	// FINANCIAL LITERACY BRINS UPDATE - START

	div#divFinancialLiteracyContainer
		section.Section2Column
			flex-direction: column
			div.DivSectionContent
				width: 100% !important
				div.DivContentNewsVertical
					margin: 0
					width: 100% !important
					margin-bottom: 20px
		section.Section4Column
			height: 300px
			font-size: 90%
			overflow-x: scroll
			padding-left: 5px
			display: flex !important
			flex-direction: column
			div.DivSectionContent
				height: auto
				width: 200px !important
				div.DivContentNewsVertical
					height: 280px
					img
						height: 150px

	// FINANCIAL LITERACY BRINS UPDATE - END


	// COMMUNITY BRINS UPDATE - START

	div#divCommunityContainer
		margin: 0 !important
		padding: 30px 50px !important
		section.Section2Column
			div.DivContent
				figure
					margin-bottom: 20px
					div.DivImageBackground
						img
							height: 300px
					figcaption
						h3
							margin-top: 20px

	// COMMUNITY BRINS UPDATE - END


	// START - ABOUT US

	div#divMainDashboard
		select, option
			font-size: 1em
		div#divBodyAbout
			div#divContentWhatBRINS
				height: 390px
				padding: 0
				div.DivContentAbout
					p
						font-size: 1em
						line-height: 22px
					h1
						margin: 0
						margin-bottom: 10px
		div#divBodyMissionAndVission
			div.DivContent2Column
				margin: 20px
				flex-direction: column
				div.DivContentImage, div.DivContent
					width: 100%
				div.DivContentImage
					img.ImageCEO
						width: 300px
						height: auto
				div.DivContent
					p
						margin-top: 10px
			div.DivContentDescription
				margin: 20px
				height: 480px !important
				div.DivContentVisionDescriptionActive, div.DivContentMissionDescriptionActive
					width: 100%
					+setBorderRadius(30px)
					div.DivContentMissionDescriptionDetail, div.DivContentVisionDescriptionDetail
						margin: 0
						padding-bottom: 0
					div.DivContentMissionDescriptionParagraph, div.DivContentVisionDescriptionParagraph
						p
							font-size: 1em
							line-height: 26px
				div.DivContentVisionDescription, div.DivContentVisionDescriptionActive
					div.DivContentVisionDescriptionParagraph
						margin: 0 20px !important
				div.DivContentVisionDescriptionInactive, div.DivContentMissionDescriptionInactive
					width: 0%
					div.DivContentVisionDescriptionDetail, div.DivContentMissionDescriptionDetail
						display: none
		div#divBodyInformation
			div.DivContentMain
				padding-bottom: 0px
				div.DivContentTextMain
					margin: 0
					h1
						font-size: 1.5em
						margin: 0px auto
					p
						font-size: 0.9em !important
						line-height: 22px
			div.DivContentDescription
				padding-top: 0px
				div.DivContentBoxDetail
					width: 100%
					height: 270px
					margin: 10px 0px !important
					+setBorderRadius(20px)
					img
						width: 80px
						height: 80px
		div#divBodyStructureOrganization
			div.DivContentMain
				h1
					font-size: 1.35em
			div.DivContentDescription
				flex-direction: column
				h3
					font-size: 1.1em
				h4
					font-size: 1em
				p
					font-size: 0.9em
					line-height: 22px
				img, img.ImageMain
					margin: auto
					margin-bottom: 20px
					width: 300px
				div.DivContentDescriptionDetail
					margin: 0
			div.DivContentMainDivision, div.DivContentDescriptionDivision
				padding: 20px 30px
				margin: 0
				img
					width: 90%
					margin: 20px auto
			div.DivContentMainDivision
				flex-direction: column-reverse
				h1
					font-size: 1.35em
					margin: 0
			div.DivContentMainDivision
				padding-bottom: 0
			div.DivContentDescriptionDivision
				padding-top: 0
				display: flex
		div.DivTabContainer
			div.DivContentTabAbout
				button
					font-size: 0.8em

		//AWARD

	div#divBodyContentMilestone
		div.DivContentHorizontal
			div.DivContentMilestoneDetail
				width: 300px
				height: 370px
				img
					width: 260px
					height: 180px
				div.DivContentPoint
					box-sizing: border-box
					height: 175px
					p.ParagraphCenter
						width: 100%
						font-size: 1em
						line-height: 22px
			div.DivContentMilestoneDetail:hover
				img
					width: 280px
					height: 190px
		div.DivContent2Column
			width: 100%
			div.DivContentAwards
				margin: 5px auto
				div#divContentAwardsDetail
					font-size: 0.9em

		//AWARD

	// START - PARTNER

	div#divBodyPartnerAndClient
		margin: 20px
		h1
			font-size: 1.2em
		div.DivContentDescription
			div.DivContentBoxDetail
				div.DivContentShapeDescription
					img:first-of-type
						height: 100px
						width: 100px
					img:last-of-type
						width: 60px
				div.DivContentBoxDescription
					padding: 0
					label
						font-size: 0.8em

	// END - PARTNER

	// END - ABOUT US

	// FOOTER - START

	div#divMainLanding, div#divMainDashboard
		div#divBodyFooterContainer
			div.DivFooterInfoPage
				div#divSectionHQContactUs
					grid-template-columns: 1fr
					div.DivFooterHQLocation
						div.DivFooterContentContainer
							margin: 20px
				div.DivFooterPageHorizontalContainer
					div.DivFooterSectionPage
						div.DivFooterLink
							div.DivFooterContentContainer
								div.DivParagraphLink
									grid-template-columns: 1fr
						div.DivFooterVerticalContainer
							div.DivFooterSocialMedia
								div.DivFooterHorizontalContainer
									gap: 0
									margin-bottom: 0
							div.DivFooterHorizontalContainer
								gap: 20px
								margin-bottom: 20px
								div.DivFooterSVGLargeContainer, div.DivFooterSVGMediumContainer
									margin: 0 !important
									img
										height: 60px
						div.DivFormSubscribeEmail
							input[type=text]
								font-size: 1em

	// FOOTER - END


	// CUSTOMER CARE - START

	// CUSTOMER CARE LANDING - START

	div#divContentCheckPolicy, div.DivBodySiteContainer, div#divContentPartnerWorkshop
		padding: 30px
		font-size: 90%
	div#divContentCheckPolicy, div#divContentPartnerWorkshop, div#divContentTicketAndCallCenter
		h2
			font-size: 1.8em
		p
			font-size: 1em
			line-height: 26px
		input[type=button], input[type=button].ButtonResponsive
			font-size: 0.9em !important
		input[type=button].ButtonResponsive
			width: 165px
			height: 40px
		input[type=button]#buttonResponsiveCariPolis
			padding: 0 60px !important
			margin: 5px auto auto auto
	div#divContentCheckPolicy
		div.DivContent2Column
			div.DivContent
				h2
					font-size: 1.8em
				p
					font-size: 1em
					line-height: 26px
				&:nth-child(2)
					padding: 0
				div.DivContentForm
					form
						div.DivContentNewsVertical
							padding: 30px
							font-size: 1em
							fieldset
								div.DivFormHorizontalContainer
									input
										font-size: 0.8em
	div.DivBodySiteContainer
		div.DivTableContainer
			width: 100%
			table
				thead
					tr
						display: grid
						grid-auto-flow: column
						padding: 10px
						font-size: 0.7em
				tbody
					tr
						display: grid
						grid-auto-flow: column
						padding: 10px
						font-size: 0.7em
						td:nth-child(1), td:nth-child(2), td:nth-child(3), td:nth-child(4)
							height: 100%
						td:nth-child(1)
							width: 50px
						td:nth-child(2)
							width: 85px
						td:nth-child(3)
							width: 50px
						td:nth-child(4)
							width: 50px
						td:nth-child(5)
							width: 40px
							div.DivContentButtonDetail
								margin: 0
								a.ButtonForwardTable
									padding: 8px 0
								span
									margin-left: 5px
								svg
									display: none
								.ButtonForwardTable:before
									width: 40px
									height: 30px
	div#divContentPartnerWorkshop
		input[type=button], input[type=button].ButtonResponsive
			margin-left: 20px !important
		div.DivContent2Column
			div.DivContent2Column
				grid-template-columns: 1fr
				div#divContentBoxDetailClaim
					div.DivContentBoxDescription
						height: 90px
						p
							margin: 0
						input[type=button].ButtonResponsive
							width: 165px
							height: 40px
			div.DivContentPartnershipWorkshop
				div.DivContentDescription
					p
						margin-bottom: 20px
			&:nth-child(2)
				div.DivContentLocationMaps
					input[type=button], input[type=button].ButtonResponsive
						margin-left: auto !important
						margin-bottom: 0 !important
					div.DivFormNoBorder
						margin: 0
						form
							div.my-google-map
								margin: 0
	div#divContentTicketAndCallCenter
		input
			font-size: 0.8em !important
		label
			font-size: 1em !important
		input[type=button].ButtonResponsive
			width: 180px !important
			margin-top: 0 !important
			margin-bottom: 0 !important
		div.DivContent2Column
			div.DivContent
				h2
					margin: 20px 0
				div.DivContentForm
					form
						padding: 0
						div.DivFormHorizontalContainer
							div.DivFormHorizontalContainer
								display: grid
								grid-template-columns: 0.2fr 1fr
								grid-gap: 5px
								input[type=text]
									width: 100%
									padding: 0
									padding-left: 5px
									background-image: none
								input[type=number]
									width: 100%
									margin-left: 0
						div.DivForm
							fieldset
								div.DivFormHorizontalContainer
									margin-bottom: 0 !important
									label, input, select, textarea
										font-size: 0.8em !important
									input
										margin-bottom: 10px
									label
										margin-top: 7.5px
							div.DivContentButtonAbout
								margin-bottom: 10px !important
								input[type=button].ButtonResponsive
									width: 180px
									margin-top: 20px !important
				div.DivContentDescription
					grid-template-columns: 1fr
					margin-bottom: 0px
					grid-gap: 0px
		div.DivContentMain
			div.DivContent
				div.DivContentDescription
					padding: 0px 20px
					margin: 20px 0 !important
				div#divContentFormSmall
					label
						font-size: 1.08em
				div#divContentFormSmall
					width: 90% !important
					fieldset
						div.DivFormHorizontalContainer
							margin-bottom: 10px !important

	// CUSTOMER CARE LANDING - END

	// PARTNERSHIP WORKSHOP - START

	div#divCustomerCareWorkshop
		div.DivContentSearch
			display: flex !important
			margin: 0 0 20px 0
			padding: 10px
			width: 100%
			box-sizing: border-box
			height: 70px
			div.DivContentSearchDetail
				width: 80%
		div#divTableContainerPartnershipWorkshop
			display: none
	div#divCustomerCareWorkshop, div#divContainerPartnershipWorkshop
		padding: 0px 20px
	div#divContainerPartnershipWorkshop
		div.DivContent2Column
			div.DivContentLocation
				display: none
			div.DivContentInfo
				display: block !important
				margin: 0
				width: 100%
				div.DivFormHorizontalContainer
					display: flex
					flex-direction: column
					margin: 20px 0 0 0
					padding: 20px
					box-sizing: border-box
					border-radius: 15px
					+boxShadow(0, 0, 8px, 0, rgba(0, 0, 0, 0.2))
					label
						margin-top: 10px
						&:first-of-type
							margin-top: 0
					a.AnchorContactDetail
						display: block
						text-decoration: none
						label
							display: block
							margin-top: 10px !important
				div.DivFooterContentContainer
					margin-top: 0px !important

	// PARTNERSHIP WORKSHOP - END

	// CUSTOMER CARE - END


	// COMPANY PROFILE - START

	div#divMainDashboard
		div#divBodyContentOrdered
			margin: 20px
			h1
				font-size: 1.5em
				line-height: 32px
			p
				font-size: 0.9em
				line-height: 24px
			li::marker
				font-size: 1em
				line-height: 22px
			div.DivContent2Column
				flex-direction: column
				div
					width: 100%
					margin-right: 0
					margin-left: 0
				div#divContentImageStategiBusiness
					img
						margin-right: auto
						margin-left: auto
				div.DivContentImage
					margin-bottom: 30px
			ol.OrderedNumber
				margin-left: 25px
			div.DivContentMain
				figure
					iframe
						height: 200px

	// COMPANY PROFILE - END


	// CORPORATE VALUE - START

	div#divMainDashboard
		div#divBodyContentOrdered
			div#divContent2ColumnCoreValue
				div.DivContent
					div.DivBodyContentOrderedList
						h2.headingFirstLetterBig
							font-size: 1.2em
						div.DivContentOrderedListImage
							width: 90px
							img
								width: 70px
			div#divContent2ColumnBRIOne
				flex-direction: row

	// CORPORATE VALUE - END


	// SOCIAL VALUE - START

	div#divBodySocialValueCEO, div#divBodySocialValueInformation, div#divBodySocialValueCSR, div#divBodyDashboardBRINSUpdate
		padding: 20px !important
		box-sizing: border-box
	div#divBodySocialValueCEO
		div.DivContent2Column
			flex-direction: column
			grid-template-columns: 1fr
			grid-column-gap: 0px
			div.DivContentImage
				img.ImageShapeCEO
					top: unset !important
			div.DivContent
				div.DivContentMessageCEO
					p
						font-size: 1em !important
						line-height: 26px !important
	div#divBodySocialValueInformation
		div#divContainMainHeader
			grid-template-columns: repeat(2, 1fr)
			grid-gap: 15px
			div.DivContentBoxDetail
				padding: 5px
				height: 180px !important
				div.DivContentDetail
					img
						width: 90px !important
					div.DivContentBoxDescription
						height: 35px
						h3
							margin-bottom: 10px
		div.DivContentDescription
			div#divBodyTabAbout
				div.DivContentMain
					p
						text-align: justify !important
						font-size: 1em !important
						line-height: 22px !important
					div.DivContentTabAbout
						button
							font-size: 0.7em !important
					h3
						text-align: center !important
						font-size: 1.3em !important
	div#divBodySocialValueInformation, div#divBodySocialValueCSR
		div.DivContentDescription
			h1
				font-size: 1.4em !important
			div#divBodyTabAbout
				div.DivContentMain
					ol.OrderedNumber
						margin-left: 30px
	div#divBodySocialValueCSR
		h1
			margin-top: 0
		div.DivContentMain
			width: 75%
			margin: auto
			grid-template-columns: 1fr
			grid-gap: 30px
			p
				font-size: 1em !important
	div#divBodyDashboardBRINSUpdate
		div#divSocialValueBRINSUpdate
			height: 400px
			div.DivContentCorporateTitle
				h1
					font-size: 1.8em
					margin-top: 0
			div.DivContentImageCorousel
				margin-top: 20px
				section#sectionImageCorousel
					width: 21vh
					height: 21vh
			div.DivContentDescription
				p
					font-size: 1em !important

	// SOCIAL VALUE - END


	// LANDING - START

	div#divMainLanding
		font-size: 90%
		overflow-x: hidden
		div#divBodyDashboardBussinesPillars
			flex-direction: column
			div.DivContentLayoutContent
				div.DivContentCorporate
					div.DivContentCorporateTitle
						h1
							font-size: 2em !important
					div.DivContentCorporatePage
						div.DivContentCorporatePageImage
							margin-top: 0px
							div.DivClipWrap
								img
									margin: auto
									left: 50%
									right: 0
									top: 50%
									translate: -50% -50%
									height: 340px
					div.DivContentCorporateDescription
						margin-left: 0px
						margin-right: 0px
						p
							height: 80px
							font-size: 1em !important
			div.DivContentLayoutSide
				flex-direction: row
				box-sizing: border-box
				margin-top: 40px
				margin-bottom: 40px
				span
					margin: 2px 3px
				span.active
					width: 45px
					height: 12px
		div#divBodyDashboardAboutBRINS
			height: 100%
			div.DivContent
				padding: 50px 20px
				div.DivContentTitle
					h1
						font-size: 0.9em
						letter-spacing: 5px
				div.DivContentDescription
					p.PCorporateDescription
						padding: 0
						font-size: 1.55em
						line-height: 50px
						padding: 20px !important
				input.ButtonResponsive2
					margin-bottom: 0px
					margin-left: 20px
		div#divBodyDashboardAward
			div.DivContentAwardsTitle
				margin: 0
				h1
					text-align: left
					font-size: 2em
					margin-left: 0
			div.DivContentAwardsContent
				flex-direction: column
				align-items: center
				align-self: center
				justify-content: space-between
				background: linear-gradient(green, green) no-repeat center/6px 100%
				margin: 40px 0
				height: 550px
				hr
					display: none
				div.DivContentCalenderDetail
					margin-top: 20px !important
					width: auto
					&:nth-of-type(even)
						margin-right: auto
						margin-left: 0
					&:nth-of-type(odd)
						margin-right: 0
						margin-left: auto
					&:last-of-type
						margin-bottom: 20px
					div.DivContentCalenderDescription
						div.DivContentCalenderTitle
							p
								font-size: 90%
			div.DivContentAwardsDescription
				margin: 0 !important
				p
					font-size: 1em !important
		div#divBodyDashboardBRINSUpdate
			padding: 0 20px 0 20px !important
			input[type=button].ButtonResponsive
				flex-direction: column
				align-self: flex-start
				margin-top: 25px
				justify-content: center
			div.DivContentCorporateSlider
				div.DivContentCorporateTitle
					height: auto !important
					h1
						font-size: 2em
						align-self: flex-start
					div.DivContentTextDetail
						p
							margin-top: 0px
							font-size: 1em
				div.DivContentImageCorousel
					section#sectionImageCorousel
						width: 21vh
						height: 21vh
				div.DivContentDescription
					h1
						margin-top: 0px
					p#PDateUpdate
						display: none
					div.DivContentBrinsUpdate
						p
							-webkit-line-clamp: 4
		div#divBodyDashboardPartners
			padding: 20px
			box-sizing: border-box
			div.DivContentPartners
				margin-left: 0
				margin-right: 0
				div.DivContent2Column
					display: flex !important
					margin: 20px !important
					height: 100%
					div.DivContent
						h2
							font-size: 2em
						p
							line-height: 26px !important
							font-size: 1em
						&:nth-of-type(2)
							display: none
			div#divContentBRIGroup
				margin-left: 20px
				margin-right: 20px
				height: auto
				h1
					margin-top: 20px
				div.DivContentImageGallery
					section#sectionImageGallery
						height: 130px
						display: flex
						label.labelImagePrevious, label.labelImageNext
							display: none
					section#sectionImageGallery label img
						border-radius: unset
		div#divBodyFooterContainer
			font-size: 111.111%

	// LANDING - END

	// MODAL - START

	div#divMainDashboard
		div.DivModal
			height: 310px !important
			width: 90%
			padding-top: 10px
			div.DivModalHeader
				height: 60px
				h2
					font-size: 1em !important
					align-self: center
					line-height: 20px
				img
					height: 30px
					width: 30px
			div.DivModalFigureContainer
				height: auto
				figure
					iframe
						height: 220px

	// MODAL - END

	// NAVIGATION MENU - START

	div#divBodyHomeMenu
		padding: 10px
		nav#navigationMainHome
			height: 60px
			margin: 0 !important
			+setBorderRadius(20px)
			div.DivNavigationHeader
				height: 60px
				img.ImageLogoBRINS
					margin: auto 15px
					height: 25px
			div.DivNavigationList
				height: 60px
				margin-right: 15px
				ul
					margin-right: 0px
					height: 60px

	nav.NavMenuOverlay
		div.DivMenuHeader
			margin: 15px 20px
			img#imageLogoDigital
				height: 25px
		ul.ListNavigation
			label
				font-size: 1em

	// NAVIGATION MENU - END

div#divNotificationInstall
	height: 85px
	p#paragraphNotificationInstallIOS
		font-size: 0.7em
	p.ParagraphNotificationInstallOthers
		font-size: 0.7em

// SCREEN WIDTH 720px - END


// SCREEN WIDTH 640px - START

@media screen and (min-width: 641px)
	div#divNotificationInstall
		width: 430px
		height: auto
		right: auto
		position: fixed
		left: $SPACE_MEDIUM
		bottom: $SPACE_MEDIUM
		+setBorderRadius($RADIUS_INPUT_GENERAL)

@media screen and (max-width: 640px)
	div#divBodyDashboard, div#divBodyRelationshipManagerDetail
		div.DivContainerHorizontal, div.DivContainerVertical
			div.DivContainer
				div.DivFormHorizontalContainer
					flex-direction: column
					align-content: center
					align-items: center

	form div.DivForm fieldset, div.DivForm
		div.DivFormHorizontalContainer
			flex-direction: column
			div.DivForm2Column
				margin-left: 0px
				margin-right: 0px
				width: 100%
			div.DivForm2ColumnNoMargin:last-of-type:nth-of-type(odd)
				div.DivImageCapture
					width: 100%

	div.DivForm, form div.DivForm fieldset
		.mat-mdc-form-field
			width: 100%

	div.DivDynamicContainer
		div.DivConversationHeader
			div.DivConversationProfile
				display: none

	div.DivTableContainer
		ul.ListTableTicket
			img
				display: none
			div.DivTicketItem
				div.DivTicketSubItem:last-of-type
					display: flex
					flex-direction: column
				div.DivTicketTail
					display: flex
					flex-shrink: 1
			li
				padding-left: $SPACE_SMALL
				padding-right: $SPACE_SMALL
		ul.ListTableConversation
			li.LiConversationContainerRight, li.LiConversationContainerLeft
				justify-content: center
				width: 100%
		div.DivTableControl
			flex-direction: column
			div.DivTableSubControl
				margin-top: $SPACE_TINY
				margin-bottom: $SPACE_TINY
		table
			margin-top: $SPACE_SMALL
			margin-bottom: $SPACE_SMALL
			thead
				tr
					th
						padding: $SPACE_THIN
						display: block
					th:first-of-type
						padding-left: 0px
						+setBorderRadiusTop($RADIUS_TABLE_GENERAL)
						+setBorderRadiusBottom(0px)
					th:last-of-type
						padding-right: 0px
						+setBorderRadiusTop(0px)
						+setBorderRadiusBottom($RADIUS_TABLE_GENERAL)
				tr:first-of-type
					th
						padding-top: $SPACE_TINY
				tr:last-of-type
					th
						padding-bottom: $SPACE_TINY
			tbody, tfoot
				tr
					td
						padding: $SPACE_THIN
						display: block
						+setBorderRadius(0px)
					td:first-of-type
						padding-top: $SPACE_TINY
						padding-left: 0px
					td:last-of-type
						padding-right: 0px
						padding-bottom: $SPACE_TINY
				tr:first-of-type
					td:first-of-type
						+setBorderRadiusTop($RADIUS_TABLE_GENERAL)
					td:last-of-type
						+setBorderRadiusTop(0px)
				tr:last-of-type
					td:first-of-type
						+setBorderRadiusTop(0px)
						+setBorderRadiusBottom(0px)
					td:last-of-type
						+setBorderRadiusBottom($RADIUS_TABLE_GENERAL)
			th, td
				text-align: center

	main.MainShow
		+setSlideTransform(0, 0px, 0px, 1.0, 1.0, 1.0)
		+setTransition(all, 0.5, ease-in)
	main.MainHide
		+setSlideTransform(-60, 180px, -180px, 0.6, 0.6, 0.6)
		+setTransition(all, 0.5, ease-out)

	// CARD INSIDE GOOGLE MAPS FOR BRANCH MENU DETAIL START //

	div#divMainDashboard
		div.DivFormCardInside
			position: relative
			margin: $SPACE_MEDIUM
			width: 100%
			z-index: 1
			margin-left: 0px
			div.DivFormBranch
				margin-bottom: $SPACE_BIG
				div.DivBranchCardInformation
					img
						border-radius: 50%
						padding: $SPACE_TINY
				input[type=button].ButtonShare
					background-position-x: 170px
				p
					margin-right: 200px
		div.DivFormNoBorder
			google-map
				.map-container
					height: 400px !important
					border-radius: $SPACE_SMALL
	div#divBodyHomeMenu
		font-size: 85%
		line-height: 26px

	// CARD INSIDE GOOGLE MAPS FOR BRANCH MENU DETAIL START //

	// START BRINS UPDATE - NEWSFEEDS

	div#divMainBRINSUpdateArticle
		img.ImageHeaderArticle
			width: 100%
		div.DivBRINSUpdateArticleDetail
			margin: 30px 35px

	// END BRINS UPDATE - NEWSFEEDS

	// START FAQ

	div#divMainTermsAndCondition
		div.DivHeaderPolicy
			height: auto
			img#imgHeaderShape
				display: none
			img#imgHeaderResponsive
				display: block
			h1
				font-size: 2em
		div.DivSiteContainer
			margin: 10px 40px
			section
				h1, h3
					margin-top: 0
					margin-bottom: 20px
					font-size: 1.5em

	// END FAQ

	// START DIV ABOUT

	div#divBodyAbout
		div.DivContentMain
			height: 100%
			div.DivContentAbout
				align-items: center
				justify-content: center
				h1
					margin: 0
					margin-top: 10px
				div.DivContentDescription
					margin-top: 20px
					p
						padding: 0 10px
						line-height: 35px
						text-align: center
				div.DivContentSearchDetailFilter
					input
						width: 100%
		div.DivContentImage
			height: auto
			figure
				width: 80%
				height: 80%
				margin-top: 10px
				margin-bottom: 10px
				img:nth-of-type(2)
					display: none
				img:nth-of-type(3)
					display: none
				img:nth-of-type(4)
					display: none
				img:nth-of-type(5)
					display: none
	div#divBodyTabAbout
		h1
			font-size: 2em
		div.DivContentMain
			div.DivContentTabAbout
				display: flex
				width: 100%

	// END DIV ABOUT

	div#divMainLanding, div#divMainDashboard
		div#divBodyFooterContainer
			div.DivFooterInfoPage
				margin-left: 0
				margin-right: 0

	div.DivInfoContainer
		flex-wrap: wrap

// SCREEN WIDTH 640px - END


// SCREEN WIDTH 490px - START

@media screen and (max-width: 490px)
	div#BotikaWidget
		bottom: 95px
		right: 10px
		div#toggle-widget
			span
				font-size: 0.8em
			div#toggle-widget-icon
				width: 50px
				height: 50px

	ul.ListTableConversation
		li.LiConversationContainerRight, li.LiConversationContainerLeft
			div.DivConversationGeneral
				div.DivConversationItem
					flex-direction: column
					align-items: flex-start
					div.DivConversationHeader
						margin-top: $SPACE_LITTLE
						div.DivConversationSubHeader
							span
								text-align: left !important
							span.SpanConversationTitle
								max-width: initial !important
	div.DivSiteContainer
		margin: 0px 20px !important

	// START BRINS UPDATE - NEWSFEEDS

	div#divMainBRINSUpdateArticle
		img.ImageHeaderArticle
			width: 80%
			height: auto
			object-fit: contain
			margin: 110px auto 30px auto
		div.DivBRINSUpdateArticleDetail
			margin: 30px 35px
			h2
				font-size: 1.1em
				margin-bottom: 15px
			label, p
				font-size: 0.9em
				line-height: 26px
			p
				margin-bottom: 10px
			div.DivContentHorizontal
				margin-bottom: 20px

	// END BRINS UPDATE - NEWSFEEDS

	// START FAQ

	div#divMainTermsAndCondition
		font-size: 90%
		div.DivHeaderPolicy
			height: 100%
			img#imgHeaderShape
				display: none
			img#imgHeaderResponsive
				display: block
			h1
				margin-top: 35px
				font-size: 1.5em
		div.DivSiteContainer
			margin: 10px 40px
			section
				h1, h3
					margin-top: 0
					margin-bottom: 20px
				h1
					font-size: 1.8em
				h3
					font-size: 1em
				p
					font-size: 1em
					line-height: 22px
				div.DivButton2Column
					button
						label
							font-size: 1em
				article
					div.DivDetailFAQ
						p
							font-size: 1em !important
							line-height: 22px
						div.DivButton2Column
							button
								label
									font-size: 1em !important
						div.DivHorizontalContainer
							label
								font-size: 1em !important

	// END FAQ


	// BRINS UPDATE MENU - START

	div#divBodyAbout
		div.DivContentMain
			height: 100%
			flex-direction: column
			padding: 25% 20px 0px 20px
			div.DivContentAbout
				align-items: center
				justify-content: center
				div.DivHeaderContent
					display: flex
					h1
						margin: 0
						margin-top: 10px
						&:nth-child(2)
							margin-left: 10px
				div.DivContentDescription
					margin-top: 20px
					p
						padding: 0
						font-size: 1em
						line-height: 26px
						text-align: center !important
					div.DivContentSearchDetailFilter
						input
							width: 100%
				div.DivContentSearch
					div.DivContentSearchDetailFilter
						input
							width: 100%
							font-size: 1em
				div.DivContentButtonAbout
					div.DivContentButtonDetail
						input[type=button].ButtonResponsive
							-webkit-appearance: none
							width: 150px
							margin-left: 25px !important
							font-weight: 600
							text-align: center
		div.DivContentImage
			height: auto
			figure
				width: 80%
				height: 80%
				margin-top: 40px
				margin-bottom: 10px
				padding: 0
				display: flex
				align-items: center
				img:nth-of-type(2), img:nth-of-type(3), img:nth-of-type(4), img:nth-of-type(5)
					display: none
	div#divBodyHeadlines, div#divFinancialLiteracy
		padding: 0px 20px
	div#divBodyHeadlines
		h2
			font-size: 1.8em
		div.DivBodyFloatMain
			section.sectionHeadlines, section.sectionNewsFeed
				div.DivContentTitleHorizontal
					height: 50px !important
				div.DivSectionHeadlines
					div.DivContentNewsVertical
						img
							height: 200px
							background-position-y: top
						div.DivContentNewsDetail
							font-size: 0.9em
							div.DivParagraphContainer
								p
									display: -webkit-box
									overflow-y: hidden
									-webkit-line-clamp: 4
									-webkit-box-orient: vertical
									&:not(:first-child)
										display: none
				div.DivContentTitleHorizontal
					input[type=button].ButtonResponsive
						display: flex !important
						box-sizing: border-box
						border-radius: 25px
						border: none
						padding: 0px 20px
						font-family: "Poppins-SemiBold"
						color: white
						background-color: #064F89
						align-self: center
						height: 30px
				div.DivSectionNewsFeed
					label
						font-size: 0.8em
				div.DivSectionNewsFeed, div#divTestimonyDetail, div.DivContentCommunity
					grid-template-columns: 1fr
					grid-row-gap: 20px
					div.DivContentTestimony
						p
							font-size: 0.9em
							overflow-y: hidden
							display: -webkit-box
							-webkit-line-clamp: 4
							-webkit-box-orient: vertical
							&:not(:first-child)
								display: none
	div#divFinancialLiteracy
		div.DivContent
			&:nth-child(1)
				width: 100% !important
				border-top-right-radius: 25px
				border-bottom-right-radius: 25px
			&:nth-child(2)
				display: none
		div.DivFinancialLiteracy
			div.DivFinancialParagraphButton
				justify-content: space-between
	div#divBodyTabAbout
		margin: 20px 40px
		h1
			font-size: 1.4em
		div.DivContentMain
			div.DivContentTabAbout
				display: flex
				width: 100%
		div.DivContentDownload
			margin-top: 10px
			div.DivContentDownloadMedium
				+setBorderRadius(15px)
				margin-top: 10px
				margin-bottom: 10px
				img
					width: 100px
				div.DivContentDownloadDescription
					label
						font-size: 1em
						line-height: 22px

	// BRINS UPDATE MENU - END


	// NEWSFEEDS BRINS UPDATE - START

	div#divBodyContentMilestone
		div.DivContent2Column
			grid-template-columns: 1fr
			div.DivContentAwards
				div.DivContentAwardsDetail
					a.ButtonForward
						right: 10px
						span
							font-size: 0.9em


	// NEWSFEEDS BRINS UPDATE - END

	// COMMUNITY BRINS UPDATE - START

	div#divCommunityContainer
		section.Section2Column
			div.DivContent
				figure
					figcaption
						p
							font-size: 0.9em
							line-height: 26px

	// COMMUNITY BRINS UPDATE - END

	// TESTIMONY BRINS UPDATE - START

	div#divTestimonyContainer
		section.Section3Column
			div.DivContentTestimony
				div.DivContentTestimonyDetail
					p, label
						font-size: 0.9em
						line-height: 26px

	// TESTIMONY BRINS UPDATE - END

	// FINANCIAL LITERACY BRINS UPDATE - START

	div#divFinancialLiteracyContainer
		section.Section2Column
			div.DivSectionContent
				h3
					font-size: 1.1em
				p
					font-size: 0.9em
					line-height: 26px
					text-align: left
		section.Section4Column
			div.DivSectionContent
				p
					font-size: 0.9em
					line-height: 26px
					text-align: left

	// FINANCIAL LITERACY BRINS UPDATE - END

	// FINANCIAL LITERACY BRINS UPDATE - START

	div#divFinancialLiteracyContainer
		section.Section2Column
			flex-direction: column
			div.DivSectionContent
				width: 100% !important
				div.DivContentNewsVertical
					margin: 0
					width: 100% !important
					margin-bottom: 20px
		section.Section4Column
			height: 300px
			font-size: 90%
			overflow-x: scroll
			padding-left: 5px
			display: flex !important
			flex-direction: column
			div.DivSectionContent
				height: auto
				width: 200px !important
				div.DivContentNewsVertical
					height: 280px
					img
						height: 150px

	// FINANCIAL LITERACY BRINS UPDATE - END


	// COMMUNITY BRINS UPDATE - START

	div#divCommunityContainer
		margin: 0 !important
		padding: 30px 50px !important
		section.Section2Column
			div.DivContent
				figure
					margin-bottom: 20px
					div.DivImageBackground
						img
							height: 300px
					figcaption
						h3
							margin-top: 20px

	// COMMUNITY BRINS UPDATE - END


	// START - ABOUT US

	div#divMainDashboard
		select, option
			font-size: 1em
		div#divBodyAbout
			div#divContentWhatBRINS
				height: 390px
				padding: 0
				div.DivContentAbout
					p
						font-size: 1em
						line-height: 22px
					h1
						margin: 0
						margin-bottom: 10px
		div#divBodyMissionAndVission
			div.DivContent2Column
				margin: 20px
				flex-direction: column
				div.DivContentImage, div.DivContent
					width: 100%
				div.DivContentImage
					img.ImageCEO
						width: 300px
						height: auto
					label#labelImageCEOAbout
						bottom: -15px
				div.DivContent
					margin-top: 30px
					p
						margin-top: 10px
			div.DivContentDescription
				margin: 20px
				height: 480px !important
				div.DivContentVisionDescriptionActive, div.DivContentMissionDescriptionActive
					width: 100%
					+setBorderRadius(30px)
					div.DivContentMissionDescriptionDetail, div.DivContentVisionDescriptionDetail
						margin: 0
						padding-bottom: 0
					div.DivContentMissionDescriptionParagraph, div.DivContentVisionDescriptionParagraph
						p
							font-size: 1em
							line-height: 26px
				div.DivContentVisionDescription, div.DivContentVisionDescriptionActive
					div.DivContentVisionDescriptionParagraph
						margin: 0 20px !important
				div.DivContentVisionDescriptionInactive, div.DivContentMissionDescriptionInactive
					width: 0%
					div.DivContentVisionDescriptionDetail, div.DivContentMissionDescriptionDetail
						display: none
		div#divBodyInformation
			div.DivContentMain
				padding-bottom: 0px
				div.DivContentTextMain
					margin: 0
					h1
						font-size: 1.5em
						margin: 0px auto
					p
						font-size: 0.9em !important
						line-height: 22px
			div.DivContentDescription
				padding-top: 0px
				div.DivContentBoxDetail
					width: 100%
					height: 270px
					margin: 10px 0px !important
					+setBorderRadius(20px)
					img
						width: 80px
						height: 80px
		div#divBodyStructureOrganization
			div.DivContentMain
				h1
					font-size: 1.35em
			div.DivContentDescription
				flex-direction: column
				h3
					font-size: 1.1em
				h4
					font-size: 1em
				p
					font-size: 0.9em
					line-height: 22px
				img, img.ImageMain
					margin: auto
					margin-bottom: 20px
					width: 300px
				div.DivContentDescriptionDetail
					margin: 0
			div.DivContentMainDivision, div.DivContentDescriptionDivision
				padding: 20px 30px
				margin: 0
				img
					width: 90%
					margin: 20px auto
			div.DivContentMainDivision
				flex-direction: column-reverse
				h1
					font-size: 1.35em
					margin: 0
			div.DivContentMainDivision
				padding-bottom: 0
			div.DivContentDescriptionDivision
				padding-top: 0
				display: flex
		div.DivTabContainer
			div.DivContentTabAbout
				button
					font-size: 0.8em

		//AWARD

	div#divBodyContentMilestone
		div.DivContentHorizontal
			div.DivContentMilestoneDetail
				width: 300px
				height: 370px
				img
					width: 260px
					height: 180px
				div.DivContentPoint
					box-sizing: border-box
					height: 175px
					p.ParagraphCenter
						width: 100%
						font-size: 1em
						line-height: 22px
			div.DivContentMilestoneDetail:hover
				img
					width: 280px
					height: 190px
		div.DivContent2Column
			width: 100%
			div.DivContentAwards
				margin: 5px auto
				div#divContentAwardsDetail
					font-size: 0.9em

		//AWARD

	// START - PARTNER

	div#divBodyPartnerAndClient
		margin: 20px
		h1
			font-size: 1.2em
		div.DivContentDescription
			div.DivContentBoxDetail
				div.DivContentShapeDescription
					img:first-of-type
						height: 100px
						width: 100px
					img:last-of-type
						width: 60px
				div.DivContentBoxDescription
					padding: 0
					label
						font-size: 0.8em

	// END - PARTNER

	// END - ABOUT US

	// FOOTER - START

	div#divMainLanding, div#divMainDashboard
		div#divBodyFooterContainer
			div.DivFooterInfoPage
				div#divSectionHQContactUs
					grid-template-columns: 1fr
					div.DivFooterHQLocation
						div.DivFooterContentContainer
							margin: 20px
				div.DivFooterPageHorizontalContainer
					div.DivFooterSectionPage
						div.DivFooterLink
							div.DivFooterContentContainer
								div.DivParagraphLink
									grid-template-columns: 1fr
						div.DivFooterVerticalContainer
							div.DivFooterSocialMedia
								div.DivFooterHorizontalContainer
									gap: 0
									margin-bottom: 0
							div.DivFooterHorizontalContainer
								gap: 20px
								margin-bottom: 20px
								div.DivFooterSVGLargeContainer, div.DivFooterSVGMediumContainer
									margin: 0 !important
									img
										height: 60px
						div.DivFormSubscribeEmail
							input[type=text]
								font-size: 1em

	// FOOTER - END


	// CUSTOMER CARE - START

	// CUSTOMER CARE LANDING - START

	div#divContentCheckPolicy, div.DivBodySiteContainer, div#divContentPartnerWorkshop
		padding: 30px
		font-size: 90%
	div#divContentCheckPolicy, div#divContentPartnerWorkshop, div#divContentTicketAndCallCenter
		h2
			font-size: 1.8em
		p
			font-size: 1em
			line-height: 26px
		input[type=button], input[type=button].ButtonResponsive
			font-size: 0.9em !important
		input[type=button].ButtonResponsive
			width: 165px
			height: 40px
		input[type=button]#buttonResponsiveCariPolis
			padding: 0 60px !important
			margin: 5px auto auto auto
	div#divContentCheckPolicy
		div.DivContent2Column
			div.DivContent
				h2
					font-size: 1.8em
				p
					font-size: 1em
					line-height: 26px
				&:nth-child(2)
					padding: 0
				div.DivContentForm
					form
						div.DivContentNewsVertical
							padding: 30px
							font-size: 1em
							fieldset
								div.DivFormHorizontalContainer
									input
										font-size: 0.8em
	div.DivBodySiteContainer
		div.DivTableContainer
			width: 100%
			table
				thead
					tr
						display: grid
						grid-auto-flow: column
						padding: 10px
						font-size: 0.7em
				tbody
					tr
						display: grid
						grid-auto-flow: column
						padding: 10px
						font-size: 0.7em
						td:nth-child(1), td:nth-child(2), td:nth-child(3), td:nth-child(4)
							height: 100%
						td:nth-child(1)
							width: 50px
						td:nth-child(2)
							width: 85px
						td:nth-child(3)
							width: 50px
						td:nth-child(4)
							width: 50px
						td:nth-child(5)
							width: 40px
							div.DivContentButtonDetail
								margin: 0
								a.ButtonForwardTable
									padding: 8px 0
								span
									margin-left: 5px
								svg
									display: none
								.ButtonForwardTable:before
									width: 40px
									height: 30px
	div#divContentPartnerWorkshop
		input[type=button], input[type=button].ButtonResponsive
			margin-left: 20px !important
		div.DivContent2Column
			div.DivContent2Column
				grid-template-columns: 1fr
				div#divContentBoxDetailClaim
					div.DivContentBoxDescription
						height: 90px
						p
							margin: 0
						input[type=button].ButtonResponsive
							width: 165px
							height: 40px
			div.DivContentPartnershipWorkshop
				div.DivContentDescription
					p
						margin-bottom: 20px
			&:nth-child(2)
				div.DivContentLocationMaps
					input[type=button], input[type=button].ButtonResponsive
						margin-left: auto !important
						margin-bottom: 0 !important
					div.DivFormNoBorder
						margin: 0
						form
							div.my-google-map
								margin: 0
	div#divContentTicketAndCallCenter
		input
			font-size: 0.8em !important
		label
			font-size: 1em !important
		input[type=button].ButtonResponsive
			width: 180px !important
			margin-top: 0 !important
			margin-bottom: 0 !important
		div.DivContent2Column
			div.DivContent
				h2
					margin: 20px 0
				div.DivContentForm
					form
						padding: 0
						div.DivFormHorizontalContainer
							div.DivFormHorizontalContainer
								display: grid
								grid-template-columns: 0.2fr 1fr
								grid-gap: 5px
								input[type=text]
									width: 100%
									padding: 0
									padding-left: 5px
									background-image: none
								input[type=number]
									width: 100%
									margin-left: 0
						div.DivForm
							fieldset
								div.DivFormHorizontalContainer
									margin-bottom: 0 !important
									label, input, select, textarea
										font-size: 0.8em !important
									input
										margin-bottom: 10px
									label
										margin-top: 7.5px
							div.DivContentButtonAbout
								margin-bottom: 10px !important
								input[type=button].ButtonResponsive
									width: 180px
									margin-top: 20px !important
				div.DivContentDescription
					grid-template-columns: 1fr
					margin-bottom: 0px
					grid-gap: 0px
		div.DivContentMain
			div.DivContent
				div.DivContentDescription
					padding: 0px 20px
					margin: 20px 0 !important
				div#divContentFormSmall
					label
						font-size: 1.08em
				div#divContentFormSmall
					width: 90% !important
					fieldset
						div.DivFormHorizontalContainer
							margin-bottom: 10px !important

	// CUSTOMER CARE LANDING - END

	// PARTNERSHIP WORKSHOP - START

	div#divCustomerCareWorkshop
		div.DivContentSearch
			display: flex !important
			margin: 0 0 20px 0
			padding: 10px
			width: 100%
			box-sizing: border-box
			height: 70px
			div.DivContentSearchDetail
				width: 80%
		div#divTableContainerPartnershipWorkshop
			display: none
	div#divCustomerCareWorkshop, div#divContainerPartnershipWorkshop
		padding: 0px 20px
	div#divContainerPartnershipWorkshop
		div.DivContent2Column
			div.DivContentLocation
				display: none
			div.DivContentInfo
				display: block !important
				margin: 0
				div.DivFormHorizontalContainer
					display: flex
					flex-direction: column
					margin: 20px 0 0 0
					padding: 20px
					box-sizing: border-box
					border-radius: 15px
					+boxShadow(0, 0, 8px, 0, rgba(0, 0, 0, 0.2))
					label
						margin-top: 10px
						&:first-of-type
							margin-top: 0
				div.DivFooterContentContainer
					margin-top: 0px !important

	// PARTNERSHIP WORKSHOP - END

	// CUSTOMER CARE - END


	// COMPANY PROFILE - START

	div#divMainDashboard
		div#divBodyContentOrdered
			margin: 20px
			h1
				font-size: 1.5em
				line-height: 32px
			p
				font-size: 0.9em
				line-height: 24px
			li::marker
				font-size: 1em
				line-height: 22px
			div.DivContent2Column
				flex-direction: column
				div
					width: 100%
					margin-right: 0
					margin-left: 0
				div#divContentImageStategiBusiness
					img
						margin-right: auto
						margin-left: auto
				div.DivContentImage
					margin-bottom: 30px
			ol.OrderedNumber
				margin-left: 25px
			div.DivContentMain
				figure
					iframe
						height: 200px

	// COMPANY PROFILE - END


	// CORPORATE VALUE - START

	div#divMainDashboard
		div#divBodyContentOrdered
			div#divContent2ColumnCoreValue
				div.DivContent
					div.DivBodyContentOrderedList
						h2.headingFirstLetterBig
							font-size: 1.2em
						div.DivContentOrderedListImage
							width: 90px
							img
								width: 70px
			div#divContent2ColumnBRIOne
				flex-direction: row

	// CORPORATE VALUE - END


	// SOCIAL VALUE - START

	div#divBodySocialValueCEO, div#divBodySocialValueInformation, div#divBodySocialValueCSR, div#divBodyDashboardBRINSUpdate
		padding: 20px !important
		box-sizing: border-box
	div#divBodySocialValueCEO
		div.DivContent2Column
			flex-direction: column
			grid-template-columns: 1fr
			grid-column-gap: 0px
			div.DivContentImage
				img.ImageShapeCEO
					top: unset !important
			div.DivContent
				div.DivContentMessageCEO
					p
						font-size: 1em !important
						line-height: 26px !important
	div#divBodySocialValueInformation
		div#divContainMainHeader
			grid-template-columns: repeat(2, 1fr)
			grid-gap: 15px
			div.DivContentBoxDetail
				padding: 5px
				height: 180px !important
				div.DivContentDetail
					img
						width: 90px !important
					div.DivContentBoxDescription
						height: 35px
						h3
							margin-bottom: 10px
		div.DivContentDescription
			div#divBodyTabAbout
				div.DivContentMain
					p
						text-align: justify !important
						font-size: 1em !important
						line-height: 22px !important
					div.DivContentTabAbout
						button
							font-size: 0.7em !important
					h3
						text-align: center !important
						font-size: 1.3em !important
	div#divBodySocialValueInformation, div#divBodySocialValueCSR
		div.DivContentDescription
			h1
				font-size: 1.4em !important
			div#divBodyTabAbout
				div.DivContentMain
					ol.OrderedNumber
						margin-left: 30px
	div#divBodySocialValueCSR
		h1
			margin-top: 0
		div.DivContentMain
			grid-template-columns: 1fr
			p
				font-size: 1em !important
	div#divBodyDashboardBRINSUpdate
		div#divSocialValueBRINSUpdate
			height: 400px
			div.DivContentCorporateTitle
				h1
					font-size: 1.8em
					margin-top: 0
			div.DivContentImageCorousel
				margin-top: 20px
				section#sectionImageCorousel
					width: 21vh
					height: 21vh
			div.DivContentDescription
				p
					font-size: 1em !important

	// SOCIAL VALUE - END


	// LANDING - START

	div#divMainLanding
		font-size: 90%
		overflow-x: hidden
		div#divBodyDashboardBussinesPillars
			height: 100%
			padding: 50px 20px
			box-sizing: border-box
			flex-direction: column
			div.DivContentLayoutContent
				div.DivContentCorporate
					div.DivContentCorporateTitle
						h1
							font-size: 2em !important
					div.DivContentCorporatePage
						div.DivContentCorporatePageImage
							margin-top: 0px
							div.DivClipWrap
								img
									margin: auto
									left: 50%
									right: 0
									top: 50%
									translate: -50% -50%
									height: 340px
							h1
								font-size: 5em
					div.DivContentCorporateDescription
						margin-left: 0px
						margin-right: 0px
						p
							height: 80px
							font-size: 1em !important
			div.DivContentLayoutSide
				flex-direction: row
				box-sizing: border-box
				margin-top: 40px
				margin-bottom: 40px
				span
					margin: 2px 3px
				span.active
					width: 45px
					height: 12px
		div#divBodyDashboardAboutBRINS
			padding: 50px 20px
			box-sizing: border-box
			div.DivContent
				padding: 0
				margin: auto 0
				div.DivContentTitle
					h1
						font-size: 0.9em
						letter-spacing: 5px
				div.DivContentDescription
					p.PCorporateDescription
						padding: 0
						font-size: 1.55em
						line-height: 40px
						padding: 20px 0px !important
				input.ButtonResponsive2
					margin-left: 0
		div#divBodyDashboardAward
			padding: 0 20px
			box-sizing: border-box
			div#divMainAwards
				padding: 20px 0px
			div.DivContentAwardsTitle
				margin: 0
				h1
					text-align: left
					font-size: 2em
					margin-left: 0
			div.DivContentAwardsContent
				flex-direction: column
				align-items: center
				justify-content: space-between
				background: linear-gradient(green, green) no-repeat center/6px 100%
				margin: 40px 0 0 0
				height: 470px
				hr
					display: none
				div.DivContentCalenderDetail
					height: auto
					div.DivContentCalenderDescription
						width: 235px
						div.DivContentCalenderTitle
							height: auto
							margin-top: 0
							margin-bottom: auto
							p
								font-size: 90%
								margin: 7.5px 10px
								line-height: 16px
						div.DivContentCalenderImage
							margin-top: auto
							margin-bottom: 0
							img
								margin: 10px 5px
					div.DivContentCalenderDate
						width: 60px
						p
							font-size: 1em
						h1
							font-size: 2.25em
			div.DivContentAwardsDescription
				margin: 0 !important
				p
					font-size: 1em !important
		div#divBodyDashboardBRINSUpdate
			padding: 0 20px 0 20px !important
			input[type=button].ButtonResponsive
				flex-direction: column
				align-self: flex-start
				margin-top: 25px
				justify-content: center
			div.DivContentCorporateSlider
				margin-left: 0px
				margin-right: 0px
				div.DivContentCorporateTitle
					height: auto !important
					h1
						font-size: 2em
						align-self: flex-start
					div.DivContentTextDetail
						p
							margin-top: 0px
							font-size: 1em
				div.DivContentImageCorousel
					overflow: hidden
					height: 210px !important
					section#sectionImageCorousel
						width: 21vh
						height: 21vh
				div.DivContentDescription
					h1
						margin-top: 0px
					p#PDateUpdate
						display: none
					div.DivContentBrinsUpdate
						p
							-webkit-line-clamp: 4
		div#divBodyDashboardPartners
			padding: 20px
			box-sizing: border-box
			div.DivContentPartners
				margin: 30px 0
				div.DivContent2Column
					display: flex !important
					margin: 20px !important
					div.DivContent
						h2
							font-size: 2em
						p
							line-height: 26px !important
							font-size: 1em
						&:nth-of-type(2)
							display: none
			div#divContentBRIGroup
				margin: 30px 0
				h1
					margin-top: 20px
				div.DivContentImageGallery
					section#sectionImageGallery
						height: 130px
						display: flex
						label.labelImagePrevious, label.labelImageNext
							display: none
					section#sectionImageGallery label img
						border-radius: unset
		div#divBodyFooterContainer
			font-size: 111.111%

	// LANDING - END

	// MODAL - START

	div#divMainDashboard
		div.DivModal
			height: 310px !important
			width: 90%
			padding-top: 10px
			div.DivModalHeader
				height: 60px
				h2
					font-size: 1em !important
					align-self: center
					line-height: 20px
				img
					height: 30px
					width: 30px
			div.DivModalFigureContainer
				height: auto
				figure
					iframe
						height: 220px

	// MODAL - END

	// NAVIGATION MENU - START

	div#divBodyHomeMenu
		padding: 10px
		nav#navigationMainHome
			height: 60px
			margin: 0 !important
			+setBorderRadius(20px)
			div.DivNavigationHeader
				height: 60px
				img.ImageLogoBRINS
					margin: auto 15px
					height: 25px
			div.DivNavigationList
				height: 60px
				margin-right: 15px
				ul
					margin-right: 0px
					height: 60px

	nav.NavMenuOverlay
		div.DivMenuHeader
			margin: 15px 20px
			img#imageLogoDigital
				height: 25px
		ul.ListNavigation
			label
				font-size: 1em

	// NAVIGATION MENU - END


div#divNotificationInstall
	height: 85px
	p#paragraphNotificationInstallIOS
		font-size: 0.7em
	p.ParagraphNotificationInstallOthers
		font-size: 0.7em

div#divBodyStructureOrganization
	div.DivContentDescription
		div.DivImageDirector
			margin-bottom: 20px

// SCREEN WIDTH 490px - END


// SCREEN WIDTH 420px - START

@media screen and (max-width: 420px)

	// START LANDING

	div#divMainLanding
		div#divBodyDashboardBussinesPillars
			div.DivContentLayoutContent
				div.DivContentCorporate
					div.DivContentCorporatePage
						div.DivContentCorporatePageImage
							h1
								font-size: 3.5em
	// END LANDING

// SCREEN WIDTH 420px - END


// SCREEN WIDTH 420px - START

@media screen and (max-width: 400px)

	// START FAQ

	div#divMainTermsAndCondition
		div.DivHeaderPolicy
			height: 100%
			img#imgHeaderShape
				display: none
			img#imgHeaderResponsive
				display: block
			h1
				margin-top: 35px
				font-size: 1.5em
		div.DivSiteContainer
			margin: 10px 40px
			section
				h1, h3
					margin-top: 0
					margin-bottom: 20px
					font-size: 1em
				p
					font-size: 1em
				div.DivButton2Column
					button
						label
							font-size: 0.9em

	// END FAQ

	// START DIV ABOUT

	div#divBodyAbout
		div.DivContentMain
			height: 100%
			div.DivContentAbout
				div.DivContentDescription
					margin-top: 20px
					p
						text-align: left
					div.DivContentSearchDetailFilter
						input
							width: 100%
				div.DivContentSearch
					div.DivContentSearchDetailFilter
						input
							width: 100%
			div.DivContentImage
				height: auto
				margin-top: 20px
		div.DivContentImage
			figure
				margin-top: 10px
				margin-bottom: 10px
				img:nth-of-type(2)
					display: none
				img:nth-of-type(3)
					display: none
				img:nth-of-type(4)
					display: none
				img:nth-of-type(5)
					display: none
	div#divBodyTabAbout
		div.DivContentMain
			div.DivContentTabAbout
				display: flex
				width: 100%

	// END DIV ABOUT

// SCREEN WIDTH 400px - END


	// SCREEN WIDTH 360PX - START

@media screen and (max-width: 360px)

	div#divMainLanding
		div#divBodyDashboardPartners
			div#divContentBRIGroup
				div.DivContentImageGallery
					section#sectionImageGallery
						label.labelImageActive
							img
								height: 30px

	// SCREEN WIDTH 360PX - END
